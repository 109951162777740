import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Ilmoitukset from './Ilmoitukset';

export default function IlmoituksetWrapper() {
  const navigate = useNavigate();

  const [ilmoitukset, setIlmoitukset] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getIlmoitukset();
  }, [])

  const getIlmoitukset = async () => {
    try {
      const response = await axios.get("/api/ilmoitus/hae-omat")

      response.data.forEach(element => {
        if (element.tarjouskauppa_paattyy) {
          const date = new Date(element.tarjouskauppa_paattyy);
          element.tarjouskauppa_paattyy = date.toLocaleString('fi-FI', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
        }
      });

      setIlmoitukset(response.data)
      setLoading(false)
    } catch(error) {
      navigate('/haku');
    }
  }

  const modifyIlmoitus = async (ilmoitus_id) => {
    navigate("/ilmoitukset/" + ilmoitus_id);
  }

  const removeIlmoitus = async (ilmoitus_id) => {
    try {
      await axios.delete("/api/ilmoitus/poista/" + ilmoitus_id);
      const updatedIlmoitukset = ilmoitukset.filter(ilmoitus => {return ilmoitus.ilmoitus_id !== ilmoitus_id });
      setIlmoitukset(updatedIlmoitukset);
    } catch(error) {
      // Implement a notification for the user.
    }
  }

  const createIlmoitus = () => {
    navigate('/ilmoitukset/uusi');
  }

  return (
      <Ilmoitukset ilmoitukset={ilmoitukset} createIlmoitus={createIlmoitus} modifyIlmoitus={modifyIlmoitus} removeIlmoitus={removeIlmoitus} loading={loading} setLoading={setLoading}/>
  );
}