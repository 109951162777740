import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

import {
    Tooltip,
    Button,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    OutlinedInput,
    ListItemText,
    Checkbox,
    Container,
    Backdrop,
    CircularProgress,
    Alert,
    AlertTitle,
    Grid,
    Link,
    InputAdornment
} from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { NumericFormat } from 'react-number-format';

const containerStyle = {
    padding: '16px',
};

const inputLabelStyle = {
	paddingRight: '2px',
	backgroundColor: "white",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
};
  
export default function KohdevahtiForm() {
    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            toimialueet: [],
            tyyppi: "Metsätila",
            budjetti: "",
            etunimi: "",
            sukunimi: "",
            phoneNum: "",
            email: "",
            password: ""
        }
    });

    const [maakuntaOptions, setMaakuntaOptions] = useState([]);

    const [userTermsAgreed, setUserTermsAgreed] = useState(false);
    const [loading, setLoading] = useState(false);

    const [sendSuccess, setSendSuccess] = useState(false);
    const [sendFailure, setSendFailure] = useState(false);

    useEffect(() => {
        getMaakunnat()
      }, []);

    const getMaakunnat = async () => {
        try {
          const response = await axios.get("/api/data/maakunnat")
          setMaakuntaOptions(response.data)
        } catch(error) {
          console.log(error)
        }
      }

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            await axios.post("/api/kohdevahti/luo", data);
            setSendSuccess(true);
            reset();
            setLoading(false);
            setTimeout(() => {
                setSendSuccess(false);
            }, 3000);
        } catch (error) {
            console.log(error);
            setSendFailure(true);
            setLoading(false);
            setTimeout(() => {
                setSendFailure(false);
            }, 3000);
        }
    };

    const handleMultipleSelectChange = (event, onChange) => {
        const { target: { value }, } = event;
    
        onChange(typeof value === 'string' ? value.split(',') : value,);
    };

    return (
        <Container maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
                {(!sendSuccess && !sendFailure) &&
                    <Grid container spacing={2} sx={containerStyle}>
                        <Grid item xs={12}>
                            <Controller
                                name="toimialueet"
                                control={control}
                                rules={{ required: 'Syötä maakunnat' }}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <FormControl sx={{width:'100%'}}>
                                        <InputLabel id="demo-multiple-checkbox-label" sx={inputLabelStyle}>Maakunnat</InputLabel>
                                        <Select
                                            id={name}
                                            labelId="demo-multiple-checkbox-label"
                                            fullWidth
                                            multiple
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                            value={value}
                                            onChange={(event) => handleMultipleSelectChange(event, onChange)}
                                            input={<OutlinedInput label="Tag" />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                        {maakuntaOptions.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox checked={value.indexOf(name) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="tyyppi"
                                control={control}
                                render={({ field: { onChange, value, name } }) => (
                                    <TextField
                                        fullWidth
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        select // tell TextField to render select
                                        variant="outlined"
                                        label="Ilmoitustyyppi"
                                    >
                                        <MenuItem key={1} value="Metsätila">
                                            Metsätila
                                        </MenuItem>
                                        <MenuItem key={2} value="Yhteismetsäosuus">
                                            Yhteismetsäosuus
                                        </MenuItem>
                                        <MenuItem key={3} value="Tontti">
                                            Tontti
                                        </MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Controller
                                name="budjetti"
                                control={control}
                                rules={{
                                    required: 'Syötä budjetti'
                                }}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <NumericFormat
                                        fullWidth
                                        isNumericString={true}
                                        decimalScale={0}
                                        allowNegative={false}
                                        allowLeadingZeros={false}
                                        thousandSeparator=" "
                                        label="Budjetti (€)*"
                                        variant='outlined'
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        value={value}
                                        name={name}
                                        onValueChange={values => {
                                        onChange({
                                            target: {
                                            value: values.value
                                            }
                                        });
                                        }}
                                        customInput={TextField}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="etunimi"
                                control={control}
                                rules={{
                                required: 'Syötä etunimi',
                                maxLength: { value: 50, message: "Etunimen oltava korkeintaan 50 merkkiä" }}}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <TextField fullWidth label="Etunimi*" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="sukunimi"
                                control={control}
                                rules={{
                                    required: 'Syötä sukunimi',
                                maxLength: { value: 50, message: "Sukunimen oltava korkeintaan 50 merkkiä" }}}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <TextField fullWidth label="Sukunimi*" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="phoneNum"
                                control={control}
                                rules={{
                                    required: 'Syötä puhelinnumero',
                                    maxLength: { value: 15, message: "Puhelinnumeron oltava korkeintaan 15 merkkiä" }
                                }}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <TextField
                                        fullWidth
                                        label="Puhelinnumero*"
                                        variant="outlined"
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: "Syötä sähköposti",
                                    maxLength: { value: 50, message: "Sähköpostin oltava korkeintaan 50 merkkiä" },
                                    pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: "Tarkista sähköposti" }
                                }}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <TextField
                                        fullWidth
                                        label="Sähköposti*"
                                        variant="outlined"
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="password"
                                control={control}
                                rules={{ 
                                    required: 'Syötä salasana',
                                }}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <TextField
                                    fullWidth
                                    label="Salasana*"
                                    variant="outlined"
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    type="password"
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" component="div" sx={{height:'100%'}}>
                                                <Tooltip title="Tallenna kohdevahtisi käyttämällä salasanaa. Saat myöhemmin tarvittaessa poistettua tilavahdin salasanan avulla." arrow placement="top-start">
                                                    <InfoOutlinedIcon sx={{ fontSize: '24px', height:'100%' }}/>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                        }}
                                />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={ <Checkbox value={userTermsAgreed} color="primary" name="termsAccepted" onChange={e => setUserTermsAgreed(e.target.checked)} />}
                                label={ <Typography variant="body2" sx={{fontSize: {xs: '12px', sm: '14px'}}}> Olen lukenut palvelun <a href='/kayttoehdot' target="_blank" rel="noreferrer">käyttöehdot</a> ja hyväksyn ne.</Typography> } 
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Button type="submit" fullWidth variant="contained" color="primary" disabled={!userTermsAgreed}>
                                Tallenna
                            </Button>
                        </Grid>
                        <Grid item xs={12} >
                        <Link href="/kohdevahti-poista" variant="body2">
                            Haluatko poistaa kohdevahtisi?
                        </Link>
                        </Grid>
                        <Grid />
                    </Grid>
                }
            </form>
            <Container sx={{marginTop: '25px'}}>
                {sendSuccess &&
                    <Alert severity="success">
                        <AlertTitle>Kohdevahti tallennettu onnistuneesti!</AlertTitle>
                    </Alert>
                }
                {sendFailure &&
                    <Alert severity="error">
                        <AlertTitle>Kohdevahdin tallentaminen epäonnistui</AlertTitle>
                        Yritä myöhemmin uudelleen tai ole yhteydessä järjestelmänvalvojaan.
                    </Alert>
                }
            </Container>
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
}