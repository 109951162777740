import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";

import axios from "axios";

import {Container, Grid, List, ListItem, Typography, Button, Backdrop, CircularProgress, Dialog, DialogActions, DialogTitle, Alert, AlertTitle } from '@mui/material';

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    marginTop: (theme) => theme.spacing(2),
    marginBottom: (theme) => theme.spacing(8)
}

const buttonContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}

const buttonStyle = {
    width: '100%'
}

const createNewButtonStyle = {
  width: {xs: '60%', sm: "40%", md: "30%"}
}

const fontStyle = {
  fontSize: "12px"
}

export default function CompanyList() {
  const navigate = useNavigate();

  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(true);
  const [removeFailure, setRemoveFailure] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [removingCompanyId, setRemovingCompanyId] = useState("");

  const handleInitialRemoveTry = (id) => {
    setRemovingCompanyId(id)
    setOpenConfirmDialog(true)
  }

  const handleRemove = async () => {
    setLoading(true)
    setOpenConfirmDialog(false)
    try {
      await axios.delete("/api/admin/poista-yritys/" + removingCompanyId);
      getCompanies();
    } catch(error) {
        setRemoveFailure(true)
        setLoading(false)
        setTimeout(() => {
            setRemoveFailure(false)
        }, 5000);
    }
  }

  useEffect(() => {
    getCompanies();
  }, [])

  const getCompanies = async () => {
    try {
      const response = await axios.get("/api/admin/yritykset");

      response.data.forEach(element => {
        const date = new Date(element.created_at);
        element.created_at = date.toLocaleString('fi-FI')
      });

      setCompanies(response.data);
      setLoading(false)
    } catch(error) {
        navigate('/haku');
      }
  }

  const closeConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <Container sx={containerStyle}>
        {removeFailure &&
            <Alert severity="error">
                <AlertTitle>Yrityksen poistaminen epäonnistui</AlertTitle>
            </Alert>
        }
      <List name="companylist">
        {companies?.map((company) => {
          return (
            <ListItem key={company.ytunus} >
                <Grid container item spacing={1} xs={12}>
                    <Grid item container xs={8}  >
                        <Grid item xs={12} sm={4}>
                        <Typography variant="body1">ID: {company.id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <Typography variant="body1">Y-tunnus: {company.ytunnus}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <Typography variant="body1">Nimi: {company.nimi}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <Typography variant="body1">Palvelu: {company.palvelunimi}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <Typography variant="body1">Näyttökerrat: {company.view_count} </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <Typography variant="body1">Klikkaukset sivulle: {company.click_count} </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <Typography variant="body1">Luotu: {company.created_at} </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sx={buttonContainerStyle}>
                        <Button sx={buttonStyle} variant="contained" color="secondary" onClick={() => {handleInitialRemoveTry(company.id)}} >
                          Poista
                        </Button>
                        <Button sx={buttonStyle} variant="contained" color="secondary" onClick={() => {navigate('/admin/companies/'+ company.id)}} >
                          Muokkaa
                        </Button>
                    </Grid>
                </Grid>
            </ListItem>
          )
        })}
        <ListItem key="overall" >
          <Typography variant="body1"><b>Näyttökerrat yhteensä:</b> {companies.reduce((acc, company) => acc + company.view_count, 0)}</Typography>
        </ListItem>
      </List>
      <Button variant="contained" color="primary" sx={createNewButtonStyle} onClick={() => {navigate('/admin/companies/uusi')}} >
        Luo uusi yritys
      </Button>
      <Dialog open={openConfirmDialog} onClose={closeConfirmDialog} >
        <DialogTitle>
          Haluatko varmasti poistaa yrityksen?
        </DialogTitle>
        <DialogActions>
          <Button variant="contained" onClick={handleRemove}>Poista yritys</Button>
          <Button variant="contained" onClick={closeConfirmDialog} autoFocus>Peruuta</Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}