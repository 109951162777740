import React from 'react';
import { Controller } from "react-hook-form";
import { NumericFormat } from 'react-number-format';

import { Grid, List, ListSubheader, ListItem, TextField } from '@mui/material';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const headerStyle = {
  fontSize: '16px',
}

export default function Kasvupaikkajakauma({control}) {

  return (
      <div >
        <Grid sx={containerStyle} item container spacing={2} xs={12}>
          <Grid item xs={12}>
              <List subheader={<ListSubheader sx={headerStyle}>Puutavaralajit (m³)</ListSubheader>}>
                <ListItem>
                  <Controller
                  name="mantytukki_maara"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={0}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      isAllowed={(values) => {return values.value < 10000000}}
                      label="Mäntytukki"
                      variant="standard"
                      InputLabelProps={{shrink: true}}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
                <Controller
                  name="kuusitukki_maara"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={0}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      isAllowed={(values) => {return values.value < 10000000}}
                      label="Kuusitukki"
                      variant="standard"
                      InputLabelProps={{shrink: true}}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
                </ListItem>
                <ListItem>
                  <Controller
                    name="koivutukki_maara"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <NumericFormat
                        isNumericString={true}
                        decimalScale={0}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        thousandSeparator=" "
                        isAllowed={(values) => {return values.value < 10000000}}
                        label="Koivutukki"
                        variant="standard"
                        InputLabelProps={{shrink: true}}
                        value={value}
                        name={name}
                        onValueChange={values => {
                          onChange({
                            target: {
                              value: values.value
                            }
                          });
                        }}
                        customInput={TextField}
                      />
                    )}
                  />
                  <Controller
                    name="mantykuitu_maara"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <NumericFormat
                        isNumericString={true}
                        decimalScale={0}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        thousandSeparator=" "
                        isAllowed={(values) => {return values.value < 10000000}}
                        label="Mäntykuitu"
                        variant="standard"
                        InputLabelProps={{shrink: true}}
                        value={value}
                        name={name}
                        onValueChange={values => {
                          onChange({
                            target: {
                              value: values.value
                            }
                          });
                        }}
                        customInput={TextField}
                      />
                    )}
                  />
                </ListItem>
                <ListItem>
                  <Controller
                    name="kuusikuitu_maara"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <NumericFormat
                        isNumericString={true}
                        decimalScale={0}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        thousandSeparator=" "
                        isAllowed={(values) => {return values.value < 10000000}}
                        label="Kuusikuitu"
                        variant="standard"
                        InputLabelProps={{shrink: true}}
                        value={value}
                        name={name}
                        onValueChange={values => {
                          onChange({
                            target: {
                              value: values.value
                            }
                          });
                        }}
                        customInput={TextField}
                      />
                    )}
                  />
                  <Controller
                    name="koivukuitu_maara"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <NumericFormat
                        isNumericString={true}
                        decimalScale={0}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        thousandSeparator=" "
                        isAllowed={(values) => {return values.value < 10000000}}
                        label="Koivukuitu"
                        variant="standard"
                        InputLabelProps={{shrink: true}}
                        value={value}
                        name={name}
                        onValueChange={values => {
                          onChange({
                            target: {
                              value: values.value
                            }
                          });
                        }}
                        customInput={TextField}
                      />
                    )}
                  />
                </ListItem>
                <ListItem>
                  <Controller
                    name="muukuitu_maara"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <NumericFormat
                        isNumericString={true}
                        decimalScale={0}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        thousandSeparator=" "
                        isAllowed={(values) => {return values.value < 10000000}}
                        label="Muu kuitu"
                        variant="standard"
                        InputLabelProps={{shrink: true}}
                        value={value}
                        name={name}
                        onValueChange={values => {
                          onChange({
                            target: {
                              value: values.value
                            }
                          });
                        }}
                        customInput={TextField}
                      />
                    )}
                  />
                  <Controller
                    name="muutukki_maara"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <NumericFormat
                        isNumericString={true}
                        decimalScale={0}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        thousandSeparator=" "
                        isAllowed={(values) => {return values.value < 10000000}}
                        label="Muu tukki"
                        variant="standard"
                        InputLabelProps={{shrink: true}}
                        value={value}
                        name={name}
                        onValueChange={values => {
                          onChange({
                            target: {
                              value: values.value
                            }
                          });
                        }}
                        customInput={TextField}
                      />
                    )}
                  />
                </ListItem>
              </List>
          </Grid>
        </Grid>
      </div>
  );
}