import React from 'react';
import { Container, Box, Typography, Grid } from '@mui/material';
import KohdevahtiForm from './KohdevahtiForm';

const containerStyle = {
  marginBottom: (theme) => theme.spacing(12),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const headerContainerStyle = {
  height: {xs: '240px', sm: '360px'},
  position: 'relative',
  marginBottom: (theme) => theme.spacing(4),
};

const headerImageStyle = {
  width: '100vw',
  height: '100%',
  objectFit: 'cover',
}

const headerTextStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  color: '#ffffff',
  fontSize: {xs: '32px', sm: '72px'},
  lineHeight: '200%'
};

const headerStyle = {
  fontSize: {xs: '18px', sm: '32px'},
  textAlign: 'center'
};

const headerContentStyle = {
  fontSize: {xs: '12px', sm: '18px'},
  textAlign: 'center'
};


const innerContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};


export default function Kohdevahti() {

  return (
    <Container maxWidth="lg" sx={containerStyle}>
      <Box component="div" sx={headerContainerStyle}>
        <img src={'../header-image.png'} alt={""} style={headerImageStyle} />
        <Typography variant='h1' sx={headerTextStyle}><b>Nettimetsä.fi</b></Typography>
      </Box>
      <Container sx={innerContainerStyle}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h1' sx={headerStyle}><b>Kohdevahti</b></Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={headerContentStyle} variant="body2">Kohdevahdin avulla saat helposti ilmoituksen sähköpostiisi, kun kriteereitäsi vastaava kohde tulee myyntiin.</Typography>
        </Grid>
      </Grid>
        <KohdevahtiForm />
      </Container>
    </Container>
  );
}