import React from 'react';
import { Controller } from "react-hook-form";
import { NumericFormat } from 'react-number-format';

import { Grid, List, ListSubheader, ListItem, TextField } from '@mui/material';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const headerStyle = {
  fontSize: '16px',
}

export default function Kehitysluokkajakauma({control}) {

  return (
      <div>
        <Grid sx={containerStyle} item container spacing={2} xs={12}>
          <Grid item xs={12}>
            <List subheader={<ListSubheader sx={headerStyle}>Kehitysluokkajakauma (ha)</ListSubheader>}>
              <ListItem>
                <Controller
                  name="aukea_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}} // Koon tulisi olla alle miljoona hehtaaria
                      label="A0 - Aukea"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
                <Controller
                  name="siemenpuumetsikko_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="S0 - Siemenpuumetsikkö"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
              </ListItem>
              <ListItem>
                <Controller
                  name="taimikkoalle13_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="T1 - Taimikko, alle 1,3m"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
                <Controller
                  name="taimikkoyli13_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="T2 - Taimikko, yli 1,3m"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
              </ListItem>
              <ListItem>
                <Controller
                  name="ylispuustoinentaimikko_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="Y1 - Ylispuustoinen taimikko"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
                <Controller
                  name="nuorikasvatusmetsikko_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="02 - Nuori kasvatusmetsikkö"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
              </ListItem>
              <ListItem>
                <Controller
                  name="varttunutkasvatusmetsikko_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="03 - Varttunut kasvatusmetsikkö"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
                <Controller
                  name="uudistuskypsametsikko_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="04 - Uudistuskypsä metsikkö"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
              </ListItem>
              <ListItem>
                <Controller
                  name="suojuspuumetsikko_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="05 - Suojuspuumetsikkö"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
                <Controller
                  name="vajaatuottoinenmetsikko_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="06 - Vajaatuottoinen metsikkö"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
              </ListItem>
              <ListItem>
                <Controller
                  name="eriikaisrakenteinenmetsikko_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="ER - Eri-ikäisrakenteinen metsikkö"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
                <Controller
                  name="eitietoa_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label=" -- Ei tietoa"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
  );
}