import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import SearchIcon from '@mui/icons-material/Search';

const inputLabelStyle = {
	fontSize: '16px',
	paddingRight: '2px',
	backgroundColor: "white",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 350,
			minWidth: 120
    },
  },
};

function getStyles(item, selectedItems, theme) {
  return {
	paddingRight:'2px',
	backgroundColor:
		selectedItems.indexOf(item) === -1
		? ''
		: theme.palette.primary.light,
  };
}

export default function MultipleSelectChip({name, items, selectedItems, setSelectedItems}) {
  const theme = useTheme();
	const inputRef  = useRef();

  const [searchText, setSearchText] = useState("");
  const [displayedOptions, setDisplayedOptions] = useState([]);

	useEffect(() => {
			setDisplayedOptions(items.length === 0 ? [] : items.filter(item => item.toLowerCase().includes(searchText.toLowerCase())))
	}, [items, searchText])

  const handleChange = (event) => {
    const { target: { value }, } = event;
    setSelectedItems(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

	const handleDelete = (e, value) => {
		setSelectedItems(selectedItems.filter((item) => item !== value))
		document.activeElement.blur();
	}

  return (
      <FormControl sx={{ m: 1, width: 250 }} >
        <InputLabel id="input-label" sx={inputLabelStyle} >{name}</InputLabel>
        <Select
          labelId="input-label"
		  id={name}
          multiple
          value={selectedItems}
          onChange={handleChange}
			onClose={() => {setTimeout(() => {
				document.activeElement.blur();
				setSearchText("");
			}, 0);}}
			onOpen={() => {setTimeout(() => {
				inputRef.current.focus()
			}, 0);}}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} onMouseDown={(e) => {e.stopPropagation()}} onDelete={(e) => handleDelete(e, value)} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps} >
			<ListSubheader >
				<TextField
					variant="standard"
					inputRef={inputRef}
					placeholder="Hae..."
					fullWidth
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						)
					}}
					onChange={(e) => setSearchText(e.target.value)}
					onKeyDown={(e) => {
						if (e.key !== "Escape") {
							// Prevents autoselecting item while typing (default Select behaviour)
							e.stopPropagation();
						}
					}}
				/>
			</ListSubheader>
          {displayedOptions.map((item) => (
			<MenuItem
				key={item}
				value={item}
				style={getStyles(item, selectedItems, theme)}
				sx={{fontSize: {xs: '12px', sm: '16px'}}}
			>
				{item}
			</MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}