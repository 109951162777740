import React, { useState } from "react";
import {
  Drawer,
  Container,
  Tabs,
  Tab,
  List,
  ListItem
} from "@mui/material";


import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import InputIcon from '@mui/icons-material/Input';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ListIcon from '@mui/icons-material/List';
import InfoIcon from '@mui/icons-material/Info';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import HailIcon from '@mui/icons-material/Hail';

import { Link } from "react-router-dom";

const buttonsStyle = {
  width: '100%'
}

const containerStyle = {
  paddingTop: (theme) => theme.spacing(2),
  backgroundColor: (theme) => theme.palette.primary.main,
  width: '100%',
  height: '100%'
}

const listStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const tabStyle = {
  width: '100%',
  color: '#fff'
}

function DrawerComponent({isLoggedIn, logOut}) {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Container>
      {isLoggedIn ?
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} anchor="right">
        <Container sx={containerStyle}>
          <List sx={listStyle}>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <Tab sx={tabStyle} icon={<SearchIcon />} label="Hae kohteita" value="/search" component={Link} to="/haku" textColor="white"/>
            </ListItem>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <Tab sx={tabStyle} icon={<AssignmentIcon />} label="Omat ilmoitukset" value="/ilmoitukset" component={Link} to="/ilmoitukset" textColor="white"/>
            </ListItem>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <Tab sx={tabStyle} icon={<AccountCircleIcon />} label="Oma profiili" value="/profile" component={Link} to="/profiili" textColor="white"/>
            </ListItem>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <Tab sx={tabStyle} icon={<InfoIcon />} label="Yhteystiedot" value="info" component={Link} to="/info" textColor="white"/>
            </ListItem>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <Tab sx={tabStyle} icon={<PersonSearchIcon />} label="Etsi palvelua" value="/palvelut" component={Link} to="/palvelut" textColor="white"/>
            </ListItem>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <Tab sx={tabStyle} icon={<AddAlertIcon />} label="Luo kohdevahti" value="/kohdevahti" component={Link} to="/kohdevahti" textColor="white"/>
            </ListItem>
          </List>
        </Container>
      </Drawer>
      :
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} anchor="right">
        <Container sx={containerStyle}>
          <List sx={listStyle}>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <Tab sx={tabStyle} icon={<SearchIcon />} label="Hae kohteita" value="/search" component={Link} to="/haku" textColor="white"/>
            </ListItem>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <Tab sx={tabStyle} icon={<InfoIcon />} label="Yhteystiedot" value="info" component={Link} to="/info" textColor="white"/>
            </ListItem>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <Tab sx={tabStyle} icon={<PersonSearchIcon />} label="Etsi palvelua" value="/palvelut" component={Link} to="/palvelut" textColor="white"/>
            </ListItem>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <Tab sx={tabStyle} icon={<AddAlertIcon />} label="Luo kohdevahti" value="/kohdevahti" component={Link} to="/kohdevahti" textColor="white"/>
            </ListItem>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <Tab sx={tabStyle} icon={<InputIcon />} label="Kirjaudu sisään" value="/kirjautuminen" component={Link} to="/kirjautuminen" textColor="white"/>
            </ListItem>
            <ListItem onClick={() => setOpenDrawer(false)}>
              <Tab sx={tabStyle} icon={<HowToRegIcon />} label="Rekisteröidy" value="/rekisteroityminen" component={Link} to="/rekisteroityminen" textColor="white"/>
            </ListItem>
          </List>
        </Container>
      </Drawer>
      }
      {isLoggedIn ?
        <Tabs sx={buttonsStyle} scrollButtons="auto" variant="fullWidth" indicatorColor="secondary" textColor="white" value={false}>
          <Tab icon={<ListIcon fontSize="large" />} label="Valikko" onClick={() => setOpenDrawer(!openDrawer)} />
          <Tab icon={<ExitToAppIcon />} label="Kirjaudu ulos" onClick={logOut} />
        </Tabs>
        :
        <Tabs sx={buttonsStyle} scrollButtons="auto" variant="fullWidth" indicatorColor="secondary" textColor="white" value={false}>
          <Tab icon={<ListIcon fontSize="large" />} label="Valikko" onClick={() => setOpenDrawer(!openDrawer)} />
          <Tab icon={<InputIcon />} label="Kirjaudu sisään" value="/kirjautuminen" component={Link} to="/kirjautuminen" />
        </Tabs>
      }
    </Container>
  );
}
export default DrawerComponent;
