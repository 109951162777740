import React from 'react';
import { Container, Typography } from '@mui/material';

const containerStyle = {
  marginBottom: (theme) => theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const headerStyle = {
    marginTop: (theme) => theme.spacing(4),
    marginBottom: (theme) => theme.spacing(2),
    alignItems: 'center',
    textAlign: 'center',
    overflowWrap: 'anywhere',
    fontSize: {xs: '24px', sm: '28px'},
  };

const textStyle = {
    fontSize: {xs: '14px', sm: '16px'},
    width: '80%',
    textAlign: 'center',
};

export default function Mediamyynti() {

  return (
    <Container maxWidth="lg" sx={containerStyle}>
        <Typography variant='h4' sx={headerStyle}>Mainosta Nettimetsässä – Tavoita metsänomistajat tehokkaasti!</Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Etsitkö tehokasta tapaa tavoittaa kohdeyleisösi? Olemme täällä auttaaksemme sinua saavuttamaan mainostavoitteesi. 
            Metsäalan mediamyynnillä on nyt uusi osoite, ja se on nettimetsa.fi!
        </Typography>
        <Typography variant='h4' sx={headerStyle}>Asiantuntijamme apunasi</Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Autamme sinua suunnittelemaan ja toteuttamaan tehokkaita mainoskampanjoita. 
            Saat käyttöösi monipuoliset mediaratkaisumme, joita räätälöimme tarpeidesi mukaan.
        </Typography>
        <Typography variant='h4' sx={headerStyle}>Kasvata näkyvyyttäsi - Ota yhteyttä tänään!</Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Kiinnostuitko? Lähetä sähköpostia <a href='mailto:yllapito@nettimetsa.fi'>yllapito@nettimetsa.fi</a> ja keskustellaan, miten voimme auttaa sinua saavuttamaan tavoitteesi.
        </Typography>
    </Container>
  );
}