import React from 'react';
import { Grid, List, ListItem, Typography } from '@mui/material';

import PuustotiedotPieChart from './PieChart';
import PuustotiedotBarChart from './BarChart';


const gridStyle = {
  justifyContent: "center",
  alignItems: "center"
}

export default function Puustotiedot({ ilmoitustiedot }) {
  let puustotiedotDataset = []
  let kasvupaikkajakaumaDataset = []
  let puutavaralajitDataset = []

  const createPuustotiedotDataset = () => {
    if(ilmoitustiedot.aukea_koko !== null && ilmoitustiedot.aukea_koko !== 0) {
      puustotiedotDataset.push({ name: 'Aukea', value: Number(ilmoitustiedot.aukea_koko), label: "A0", color: "#C9E5CE"})
    }
    if(ilmoitustiedot.siemenpuumetsikko_koko !== null && ilmoitustiedot.siemenpuumetsikko_koko !== 0) {
      puustotiedotDataset.push({ name: 'Siemenpuumetsikkö', value: Number(ilmoitustiedot.siemenpuumetsikko_koko), label: "S0", color: "#B0D8B8" })
    }
    if(ilmoitustiedot.taimikkoalle13_koko !== null && ilmoitustiedot.taimikkoalle13_koko !== 0) {
      puustotiedotDataset.push({ name: 'T1 - Taimikko alle 1,3m', value: Number(ilmoitustiedot.taimikkoalle13_koko), label: "T1", color: "#99CBA3" })
    }
    if(ilmoitustiedot.taimikkoyli13_koko !== null && ilmoitustiedot.taimikkoyli13_koko !== 0) {
      puustotiedotDataset.push({ name: 'T2 - Taimikko yli 1,3m', value: Number(ilmoitustiedot.taimikkoyli13_koko), label: "T2", color: "#84BE8F" })
    }
    if(ilmoitustiedot.ylispuustoinentaimikko_koko !== null && ilmoitustiedot.ylispuustoinentaimikko_koko !== 0) {
      puustotiedotDataset.push({ name: 'Y1 - Ylipuustoinen taimikko', value: Number(ilmoitustiedot.ylispuustoinentaimikko_koko), label: "Y1", color: "#70B17C" })
    }
    if(ilmoitustiedot.nuorikasvatusmetsikko_koko !== null && ilmoitustiedot.nuorikasvatusmetsikko_koko !== 0) {
      puustotiedotDataset.push({ name: '02 - Nuori kasvatusmetsikkö', value: Number(ilmoitustiedot.nuorikasvatusmetsikko_koko), label: "02", color: "#3E8A4C" })
    }
    if(ilmoitustiedot.varttunutkasvatusmetsikko_koko !== null && ilmoitustiedot.varttunutkasvatusmetsikko_koko !== 0) {
      puustotiedotDataset.push({ name: '03 - Varttunut kasvatusmetsikkö', value: Number(ilmoitustiedot.varttunutkasvatusmetsikko_koko), label: "03", color: "#317D3F" })
    }
    if(ilmoitustiedot.uudistuskypsametsikko_koko !== null && ilmoitustiedot.uudistuskypsametsikko_koko !== 0) {
      puustotiedotDataset.push({ name: '04 - Uudistuskypsä metsikkö', value: Number(ilmoitustiedot.uudistuskypsametsikko_koko), label: "04", color: "#2C7038" })
    }
    if(ilmoitustiedot.suojuspuumetsikko_koko !== null && ilmoitustiedot.suojuspuumetsikko_koko !== 0) {
      puustotiedotDataset.push({ name: '05 - Suojuspuumetsikkö', value: Number(ilmoitustiedot.suojuspuumetsikko_koko), label: "05", color: "#5EA46B" })
    }
    if(ilmoitustiedot.vajaatuottoinenmetsikko_koko !== null && ilmoitustiedot.vajaatuottoinenmetsikko_koko !== 0) {
      puustotiedotDataset.push({ name: '06 - Vajaatuottoinen metsikkö', value: Number(ilmoitustiedot.vajaatuottoinenmetsikko_koko), label: "06", color: "#4D975B" })
    }
    if(ilmoitustiedot.eriikaisrakenteinenmetsikko_koko !== null && ilmoitustiedot.eriikaisrakenteinenmetsikko_koko !== 0) {
      puustotiedotDataset.push({ name: 'ER - Eri-ikäisrakenteinen metsikkö', value: Number(ilmoitustiedot.eriikaisrakenteinenmetsikko_koko), label: "ER" })
    }
    if(ilmoitustiedot.eitietoa_koko !== null && ilmoitustiedot.eitietoa_koko !== 0) {
      puustotiedotDataset.push({ name: 'Ei tietoa', value: Number(ilmoitustiedot.eitietoa_koko), label: "Ei tietoa", color: "#E3F2E6" })
    }
  }

  const createKasvupaikkajakaumaDataset = () => {
    if(ilmoitustiedot.lehto_koko !== null && ilmoitustiedot.lehto_koko !== 0) {
      kasvupaikkajakaumaDataset.push({ name: 'Lehto', value: Number(ilmoitustiedot.lehto_koko), label: "Lehto", color: "#C9E5CE"})
    }
    if(ilmoitustiedot.lehtomainenkangas_koko !== null && ilmoitustiedot.lehtomainenkangas_koko !== 0) {
      kasvupaikkajakaumaDataset.push({ name: 'Lehtomainen kangas', value: Number(ilmoitustiedot.lehtomainenkangas_koko), label: "OMT", color: "#B0D8B8" })
    }
    if(ilmoitustiedot.tuorekangas_koko !== null && ilmoitustiedot.tuorekangas_koko !== 0) {
      kasvupaikkajakaumaDataset.push({ name: 'Tuore kangas', value: Number(ilmoitustiedot.tuorekangas_koko), label: "MT", color: "#99CBA3" })
    }
    if(ilmoitustiedot.kuivahkokangas_koko !== null && ilmoitustiedot.kuivahkokangas_koko !== 0) {
      kasvupaikkajakaumaDataset.push({ name: 'Kuivahko kangas', value: Number(ilmoitustiedot.kuivahkokangas_koko), label: "VT", color: "#84BE8F" })
    }
    if(ilmoitustiedot.kuivakangas_koko !== null && ilmoitustiedot.kuivakangas_koko !== 0) {
      kasvupaikkajakaumaDataset.push({ name: 'Kuiva kangas', value: Number(ilmoitustiedot.kuivakangas_koko), label: "CT", color: "#70B17C" })
    }
    if(ilmoitustiedot.karukkokangas_koko !== null && ilmoitustiedot.karukkokangas_koko !== 0) {
      kasvupaikkajakaumaDataset.push({ name: 'Karukkokangas', value: Number(ilmoitustiedot.karukkokangas_koko), label: "CIT", color: "#3E8A4C" })
    }
    if(ilmoitustiedot.kitumaa_koko !== null && ilmoitustiedot.kitumaa_koko !== 0) {
      kasvupaikkajakaumaDataset.push({ name: 'Kitumaa', value: Number(ilmoitustiedot.kitumaa_koko), label: "Kitumaa", color: "#317D3F" })
    }
    if(ilmoitustiedot.joutomaa_koko !== null && ilmoitustiedot.joutomaa_koko !== 0) {
      kasvupaikkajakaumaDataset.push({ name: 'Joutomaa', value: Number(ilmoitustiedot.joutomaa_koko), label: "Joutomaa", color: "#2C7038" })
    }
  }

  const createPuutavaralajitDataset = () => {
    if(ilmoitustiedot.mantytukki_maara !== null && ilmoitustiedot.mantytukki_maara !== 0) {
      puutavaralajitDataset.push({ name: 'MÄT', fullname: 'Mäntytukki', value: Number(ilmoitustiedot.mantytukki_maara), color: "#C9E5CE", label: `${ilmoitustiedot.mantytukki_maara} m³`})
    }
    if(ilmoitustiedot.kuusitukki_maara !== null && ilmoitustiedot.kuusitukki_maara !== 0) {
      puutavaralajitDataset.push({ name: 'KUT', fullname: 'Kuusitukki', value: Number(ilmoitustiedot.kuusitukki_maara), color: "#B0D8B8", label: `${ilmoitustiedot.kuusitukki_maara} m³`})
    }
    if(ilmoitustiedot.koivutukki_maara !== null && ilmoitustiedot.koivutukki_maara !== 0) {
      puutavaralajitDataset.push({ name: 'KOT', fullname: 'Koivutukki', value: Number(ilmoitustiedot.koivutukki_maara), color: "#99CBA3", label: `${ilmoitustiedot.koivutukki_maara} m³`})
    }
    if(ilmoitustiedot.mantykuitu_maara !== null && ilmoitustiedot.mantykuitu_maara !== 0) {
      puutavaralajitDataset.push({ name: 'MÄK', fullname: 'Mäntykuitu', value: Number(ilmoitustiedot.mantykuitu_maara), color: "#84BE8F", label: `${ilmoitustiedot.mantykuitu_maara} m³`})
    }
    if(ilmoitustiedot.kuusikuitu_maara !== null && ilmoitustiedot.kuusikuitu_maara !== 0) {
      puutavaralajitDataset.push({ name: 'KUK', fullname: 'Kuusikuitu', value: Number(ilmoitustiedot.kuusikuitu_maara), color: "#70B17C", label: `${ilmoitustiedot.kuusikuitu_maara} m³`})
    }
    if(ilmoitustiedot.koivukuitu_maara !== null && ilmoitustiedot.koivukuitu_maara !== 0) {
      puutavaralajitDataset.push({ name: 'KOK', fullname: 'Koivukuitu', value: Number(ilmoitustiedot.koivukuitu_maara), color: "#3E8A4C", label: `${ilmoitustiedot.koivukuitu_maara} m³`})
    }
    if(ilmoitustiedot.muukuitu_maara !== null && ilmoitustiedot.muukuitu_maara !== 0) {
      puutavaralajitDataset.push({ name: 'MK', fullname: 'Muu kuitu', value: Number(ilmoitustiedot.muukuitu_maara), color: "#317D3F", label: `${ilmoitustiedot.muukuitu_maara} m³`})
    }
    if(ilmoitustiedot.muutukki_maara !== null && ilmoitustiedot.muutukki_maara !== 0) {
      puutavaralajitDataset.push({ name: 'MT', fullname: 'Muu tukki', value: Number(ilmoitustiedot.muutukki_maara), color: "#307E3E", label: `${ilmoitustiedot.muutukki_maara} m³` })
    }
  }

  createPuustotiedotDataset()
  createKasvupaikkajakaumaDataset()
  createPuutavaralajitDataset()

  return (
    <Grid container xs={12}>
      <Grid item xs={12} container>
        <List sx={{marginBottom: '30px', width: "100%"}}>
          <ListItem>
            <Grid item container spacing={2} xs={12} sx={gridStyle}>
              { ilmoitustiedot.puustonlaatu &&
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><b>Puuston yleinen laatu: </b>{ilmoitustiedot.puustonlaatu}</Typography>
                </Grid>
              }
              { ilmoitustiedot.metsanhoidollinentila &&
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><b>Metsänhoidollinen tila: </b>{ilmoitustiedot.metsanhoidollinentila}</Typography>
                </Grid>
              }
              { ilmoitustiedot.korjuuolosuhteet &&
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><b>Metsätilan korjuuolosuhteet: </b>{ilmoitustiedot.korjuuolosuhteet}</Typography>
                </Grid>
              }
              { ilmoitustiedot.hakkuumahdollisuudet &&
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1"><b>Heti olevat hakkuumahdollisuudet: </b>{ilmoitustiedot.hakkuumahdollisuudet ? ilmoitustiedot.hakkuumahdollisuudet + " m³" : ""} </Typography>
                </Grid>
              }
            </Grid>
          </ListItem>
        </List>
        <Grid item xs={11} sm={12} sx={{height:'400px', display: puutavaralajitDataset.length === 0 ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center',}} >
          <Typography variant="body1"><b>Ainespuun määrä</b></Typography>
          <PuustotiedotBarChart dataset={puutavaralajitDataset}/>
        </Grid>
        <Grid item xs={12} sm={10} md={6} lg={6} sx={{display: puustotiedotDataset.length === 0 ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Typography variant="body1" sx={{marginTop: '80px', marginBottom: '-50px'}}><b>Kehitysluokkajakauma</b></Typography>
          <PuustotiedotPieChart dataset={puustotiedotDataset}/>
        </Grid>
        <Grid item xs={12} sm={10} md={6} lg={6} sx={{display: kasvupaikkajakaumaDataset.length === 0 ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Typography variant="body1" sx={{marginTop: '80px', marginBottom: '-50px'}}><b>Kasvupaikkajakauma</b></Typography>
          <PuustotiedotPieChart dataset={kasvupaikkajakaumaDataset}/>
        </Grid>
      </Grid>
    </Grid>
  );
}