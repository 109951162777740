import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import {Button, TextField, FormControlLabel, Checkbox, Link, Grid, Container, Alert, AlertTitle, Typography} from '@mui/material';

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: (theme) => theme.spacing(8),
    marginTop: (theme) => theme.spacing(8),
    marginBottom: (theme) => theme.spacing(12),
    borderStyle: 'solid',
    borderWidth: '5px',
    borderColor: (theme) => theme.palette.primary.light,
    borderRadius: '25px',
};

const linkStyle = {
  textAlign: 'center',
}

export default function Login({setIsLoggedIn}) {
  const navigate = useNavigate();
  const [loginFailure, setLoginFailure] = useState(false);
  const [loginFailureMessage, setLoginFailureMessage] = useState('');
  const [rememberLogin, setRememberLogin] = useState(false)
  const { handleSubmit, control } = useForm({ defaultValues: {
    email: "",
    password: ""
  }});

  const onSubmit = async (data) => {
    try {
      await axios.post("/api/user/kirjautuminen", {...data, rememberLogin})

      // Navigating to the front page after succesfull login.
      setIsLoggedIn(true)
      navigate('/haku');
    } catch(error) {
      if(error.response.data === 'Unauthorized') {
        setLoginFailureMessage('Tarkista sähköposti ja salasana');
      } else {
        setLoginFailureMessage('Yritä myöhemmin uudelleen tai ole yhteydessä järjestelmänvalvojaan.')
      }
      setLoginFailure(true)
      setTimeout(() => {
        setLoginFailure(false)
        setLoginFailureMessage('')
      }, 5000);
    }
  }

  return (
    <Container maxWidth="xs" sx={containerStyle}>
      <Typography variant="h4" component="h1" gutterBottom sx={linkStyle}>Kirjaudu sisään</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Syötä sähköposti',
                  maxLength: { value: 50, message: "Sähköpostin oltava korkeintaan 50 merkkiä" },
                  pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: "Tarkista sähköposti" }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField fullWidth label="Sähköposti" name="email" variant="outlined" value={value} onChange={onChange} autoFocus error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
            <Grid item xs={12} >
              <Controller
                name="password"
                control={control}
                rules={{ 
                  required: 'Syötä salasana',
                  minLength: { value: 8, message: "Salasanan oltava vähintään 8 merkkiä" }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField fullWidth label="Salasana" name="password" variant="outlined" value={value} onChange={onChange} type="password" error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel control={<Checkbox value="remember" color="primary" onChange={ e => setRememberLogin(e.target.checked)} />} label="Muista minut" />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" fullWidth variant="contained" color="primary" >
                KIRJAUDU
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} sx={linkStyle}>
              <Link href="/salasana-resetointi" variant="body2">
                Unohditko salasanan?
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} sx={linkStyle}>
              <Link href="/rekisteroityminen" variant="body2">
                Oletko uusi käyttäjä?
              </Link>
            </Grid>
            <Grid item xs={12}>
              {loginFailure &&
                <Alert severity="error">
                  <AlertTitle>Kirjautuminen epäonnistui</AlertTitle>
                  {loginFailureMessage}
                </Alert>
              }
            </Grid>
          </Grid>
        </form>
    </Container>
  );
}