import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import axios from "axios";
import MyyntiIlmoitus from './Myynti-ilmoitus';
import ilmoitusDefaultValues from './defaultvalues'

import dayjs from 'dayjs';

import { Backdrop, CircularProgress, } from '@mui/material';

export default function MyyntiIlmoitusWrapper({isLoggedIn}) {

  const { id } = useParams()
  const navigate = useNavigate();

  const [ilmoitustiedot, setIlmoitustiedot] = useState(ilmoitusDefaultValues)
  const [loading, setLoading] = useState(true);

  const getIlmoitus = async (ilmoitus_id) => {
    try {
      const ilmoitustiedot = await axios.get("/api/ilmoitus/hae-ilmoitus-muokkaus/" + ilmoitus_id )

      if (ilmoitustiedot.data.tarjouskauppa_paattyy) {
        const date = new Date(ilmoitustiedot.data.tarjouskauppa_paattyy);
        ilmoitustiedot.data.tarjouskauppa_paattyy = dayjs(date)
      }

      if (ilmoitustiedot.data.metsa_arvio_ajankohta) {
        const date = new Date(ilmoitustiedot.data.metsa_arvio_ajankohta);
        ilmoitustiedot.data.metsa_arvio_ajankohta = dayjs(date)
      }

      setIlmoitustiedot(ilmoitustiedot.data)
      setLoading(false);
    } catch(error) {
      console.log(error)
      navigate('/');
    }
  }


  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/haku');
    }
    if (id === "uusi") {
      setLoading(false);
    } else {
      getIlmoitus(id)
    }
  }, [])


  return (
    <div>
      {loading?
        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      :
        <MyyntiIlmoitus id={id} ilmoitustiedot={ilmoitustiedot} />
      }
    </div>
  );
}