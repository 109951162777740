import React from 'react';
import { Container, Box, Typography } from '@mui/material';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';

const containerStyle = {
  marginBottom: (theme) => theme.spacing(12),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const headerContainerStyle = {
  height: {xs: '240px', sm: '360px'},
  position: 'relative',
  marginBottom: (theme) => theme.spacing(4),
};

const headerImageStyle = {
  width: '100vw',
  height: '100%',
  objectFit: 'cover',
}

const headerTextStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  color: '#ffffff',
  fontSize: {xs: '32px', sm: '72px'},
  lineHeight: '200%'
};


const innerContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: { xs: '25px', sm: '45px' },
  borderStyle: 'solid',
  borderWidth: '5px',
  borderColor: (theme) => theme.palette.primary.light,
  borderRadius: '25px',
};

const iconStyle = {
  fontSize: { xs: '18px', sm: '16px' },
  marginBottom: '-2px',
  marginRight: '6px'
}

const textStyle = {
  fontSize: {xs: '16px', sm: '18px'},
  textAlign: 'center'
}

const smallHeaderStyle = {
  fontSize: {xs: '24px', sm: '28px'},
  textAlign: 'center'
}

export default function ContactInfo() {

  return (
    <Container maxWidth="lg" sx={containerStyle}>
      <Box component="div" sx={headerContainerStyle}>
        <img src={'../header-image.png'} alt={""} style={headerImageStyle} />
        <Typography variant='h1' sx={headerTextStyle}><b>Nettimetsä.fi</b></Typography>
      </Box>
      <Container sx={innerContainerStyle}>
        <Typography variant="h3" sx={smallHeaderStyle} paragraph>
          Yhteystiedot
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary" paragraph sx={textStyle}>
          Ota rohkeasti yhteyttä, mikäli tarvitset apua palvelun käytössä, 
          sinulla on kehitysideoita tai kysymyksiä palveluun liittyen. Lisäksi, jos haluat tehdä yhteistyötä Nettimetsän kanssa, ilmoittaa palvelusi tai hankkia mainostilaa sivustolta, olemme käytettävissäsi.
        </Typography>
        <Typography variant="body1" paragraph sx={textStyle}><PhoneIcon sx={iconStyle}/>
          Asiakaspalvelu <a href={`tel:0400504124`}>0400504124</a>
        </Typography>
        <Typography variant="body1" paragraph sx={textStyle}><MailOutlineIcon sx={iconStyle} />
          Ylläpito <a href='mailto:yllapito@nettimetsa.fi'>yllapito@nettimetsa.fi</a>
        </Typography>
      </Container>
    </Container>
  );
}