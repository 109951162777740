import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

import axios from "axios";
import Perustiedot from './Perustiedot';
import Puustotiedot from './Puustotiedot';
import Lisatiedot from './Lisatiedot';
import Yhteismetsatiedot from './Yhteismetsatiedot';

import Kuvat from './Kuvat'
import Liitteet from './Liitteet';

import { Container, Button, List, ListItem, ListItemText, ListItemButton, Collapse, Backdrop, CircularProgress } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const rootContainerStyle = {
  marginBottom: (theme) => theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const containerStyle = {
  marginTop: (theme) => theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const buttonContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
}

const sectionButtonStyle = {
  width: '100%',
}

const buttonStyle = {
  margin: (theme) => theme.spacing(2, 2),
  width: "30%"
}

export default function MyyntiIlmoitus( {id, ilmoitustiedot} ) {
  const navigate = useNavigate();

  const [openPuustotiedot, setOpenPuustotiedot] = useState(false)
  const [openYhteismetsatiedot, setOpenYhteismetsatiedot] = useState(ilmoitustiedot.tyyppi === 'Yhteismetsäosuus')
  const [openLisatiedot, setOpenLisatiedot] = useState(false)

  const [loading, setLoading] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registrationFailure, setRegistrationFailure] = useState(false);

  const { handleSubmit, control, watch, reset, setValue, getValues, formState: { errors } } =  useForm({ defaultValues: ilmoitustiedot})

  const ilmoitustyyppiSelect = useRef({});
  ilmoitustyyppiSelect.current = watch("tyyppi");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const createNew = (data) => {
    const fData = new FormData()

    const newPictures = data.kuvat.filter((item) => { return ((item instanceof File))})
    const newAttachments = data.liitteet.filter((item) => { return ((item instanceof File))})

    newAttachments.forEach(tiedosto=>{ fData.append("liitetiedostot", tiedosto)});
    newPictures.forEach(tiedosto=>{ fData.append("kuvatiedostot", tiedosto)});

    delete data.kuvat
    delete data.liitteet

    for (const key in data) {
      fData.append(key, data[key])
    }

    fData.append("ilmoitus_id", id);
    return fData
  }

  const updateExisting = (data) => {
    const fData = new FormData()

    data.paakuva = null

    // Let's take the first item of the kuvat-array as the pääkuva for the ilmoitus
    if (data.kuvat && data.kuvat.length > 0 && data.kuvat[0].ilmoituskuva_id) {
      data.paakuva = data.kuvat[0].ilmoituskuva_id
    }

    const newPictures = data.kuvat.filter((item) => { return ((item instanceof File))})
    const oldPictures = data.kuvat.filter((item) => { return (!(item instanceof File))})

    const newAttachments = data.liitteet.filter((item) => { return ((item instanceof File))})
    const oldAttachments = data.liitteet.filter((item) => { return (!(item instanceof File))})

    newAttachments.forEach(tiedosto=>{ fData.append("liitetiedostot", tiedosto)});
    newPictures.forEach(tiedosto=>{ fData.append("kuvatiedostot", tiedosto)});

    data.kuvat = oldPictures.map((picture) => { return picture.ilmoituskuva_id })
    data.liitteet = oldAttachments.map((attachment) => { return attachment.ilmoitusliite_id })

    for (const key in data) {
      fData.append(key, data[key])
    }

    return fData
  }

  const onSubmit = async (data) => {
    setLoading(true)

    if (data.tarjouskauppa !== 'false' && data.tarjouskauppa_paattyy) {
      // If the date is set, we first convert it to a UTC-time and after that to the standard ISO format
      const utcDate = new Date(data.tarjouskauppa_paattyy.$d.getTime());
      const ISODate = utcDate.toISOString();
      data.tarjouskauppa_paattyy = ISODate
    }

    if (data.metsa_arvio !== 'false' && data.metsa_arvio_ajankohta) {
      // If the date is set, we first convert it to a UTC-time and after that to the standard ISO format
      const utcDate = new Date(data.metsa_arvio_ajankohta.$d.getTime());
      const ISODate = utcDate.toISOString();
      data.metsa_arvio_ajankohta = ISODate
    }

    if (id === "uusi") {
      const fData = createNew(data)
      try {
        await axios.post("/api/ilmoitus/uusi", fData)
        setLoading(false)
        setRegistrationSuccess(true)
        setTimeout(() => {
          setRegistrationSuccess(false)
          navigate('/ilmoitukset');
        }, 2000);
      } catch(error) {
        setLoading(false)
        setRegistrationFailure(true)
        setTimeout(() => {
          setRegistrationFailure(false)
        }, 5000);
      }
    } else {
      const fData = updateExisting(data)
      try {
        await axios.post("/api/ilmoitus/paivita/" + id, fData)
        setLoading(false)
        setRegistrationSuccess(true)
        setTimeout(() => {
          setRegistrationSuccess(false)
          navigate('/ilmoitukset');
        }, 2000);
      } catch(error) {
        setLoading(false)
        setRegistrationFailure(true)
        setTimeout(() => {
          setRegistrationFailure(false)
        }, 5000);
      }
    }
  }

  const cancelChanges = () => {
    reset();
  }

  const onError = () => {
      window.scrollTo(0, 0);
  }

  return (
    <Container maxWidth="md" sx={rootContainerStyle}>
      {!registrationSuccess &&
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <List sx={containerStyle}>
            <ListItem>
              <Perustiedot control={control} watch={watch} setValue={setValue} getValues={getValues} setOpenYhteismetsatiedot={setOpenYhteismetsatiedot}/>
            </ListItem>
            <ListItemButton onClick={() => {setOpenPuustotiedot(!openPuustotiedot)}} sx={sectionButtonStyle}>
              <ListItemText primary="Puustotiedot" />
              {openPuustotiedot ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openPuustotiedot} timeout="auto">
              <Puustotiedot control={control} setValue={setValue}/>
            </Collapse>
            <ListItemButton onClick={() => {setOpenYhteismetsatiedot(!openYhteismetsatiedot)}} sx={sectionButtonStyle}>
              <ListItemText primary="Yhteismetsätiedot" />
              {openYhteismetsatiedot ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openYhteismetsatiedot} timeout="auto">
              <Yhteismetsatiedot control={control} ilmoitustyyppiSelect={ilmoitustyyppiSelect}/>
            </Collapse>
            <ListItemButton onClick={() => {setOpenLisatiedot(!openLisatiedot)}} sx={sectionButtonStyle}>
              <ListItemText primary="Lisätiedot" />
              {openLisatiedot ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openLisatiedot} timeout="auto">
              <Lisatiedot control={control} />
            </Collapse>
            <ListItem>
                <Kuvat control={control} getValues={getValues} setValue={setValue}/>
            </ListItem>
            <ListItem>
                <Liitteet control={control} getValues={getValues} setValue={setValue}/>
            </ListItem>
            {registrationFailure &&
              <Alert severity="error">
                <AlertTitle>Tallentaminen epäonnistui</AlertTitle>
                Kokeile myöhemmin uudelleen tai ole yhteydessä järjestelmänvalvojaan.
              </Alert>
            }
            <Container style={buttonContainerStyle}> 
              <Button sx={buttonStyle} variant="contained" color="secondary" onClick={() => {cancelChanges()}}  >
                PERUUTA
              </Button>
              <Button sx={buttonStyle} type="submit" variant="contained" color="primary">
                {id === "uusi" ? "Julkaise ilmoitus" : "Tallenna"}
              </Button>
            </Container>
          </List>
        </form>
      }
      <Container maxWidth="xs" sx={containerStyle}>
        {registrationSuccess &&
          <Alert severity="success">
            <AlertTitle>Tallentaminen onnistui</AlertTitle>
            Odota hetki, sinut siirretään omiin ilmoituksiisi.
          </Alert>
        }
      </Container>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}