import React from 'react';
import { Controller } from "react-hook-form";
import {Grid, InputLabel, NativeSelect, TextField, Container  } from '@mui/material';

const containerStyle = {
  width: {xs: '100%', sm: "70%"}
}

export default function Lisatiedot({control}) {

  return (
    <Container sx={containerStyle}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputLabel >Rasitteet ja rajoitukset</InputLabel>
          <Controller
            name="rajoitteet"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <NativeSelect fullWidth value={value} name={name} onChange={onChange}>
                <option value={"On"}>On</option>
                <option value={"Ei ole"}>Ei ole</option>
              </NativeSelect>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Tieoikeudet</InputLabel>
          <Controller
            name="tieoikeudet"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <NativeSelect fullWidth value={value} name={name} onChange={onChange}>
                <option value={"On"}>On</option>
                <option value={"Ei ole"}>Ei ole</option>
              </NativeSelect>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Osuus yhteisiin</InputLabel>
          <Controller
            name="osuus_yhteisiin"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <NativeSelect fullWidth value={value} name={name} onChange={onChange}>
                <option value={"On"}>On</option>
                <option value={"Ei ole"}>Ei ole</option>
              </NativeSelect>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel >Kaava</InputLabel>
          <Controller
            name="kaava"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <NativeSelect fullWidth value={value} name={name} onChange={onChange}>
                <option value={"On"}>On</option>
                <option value={"Ei ole"}>Ei ole</option>
              </NativeSelect>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Rakennusoikeus</InputLabel>
          <Controller
            name="rakennusoikeus"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <NativeSelect fullWidth value={value} name={name} onChange={onChange}>
                <option value={"On"}>On</option>
                <option value={"Ei ole"}>Ei ole</option>
              </NativeSelect>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="videolinkki"
            control={control}
            rules={{
              maxLength: { value: 200, message: "Kentän oltava korkeintaan 200 merkkiä" },
            }}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                label="Linkki videoon"
                value={value}
                name={name}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : "Voit halutessa lisätä linkin videoon, jossa esitellään myytävänä oleva kohde."}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="kotisivulinkki"
            control={control}
            rules={{
              maxLength: { value: 200, message: "Kentän oltava korkeintaan 200 merkkiä" },
            }}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                label="Linkki kotisivulle"
                value={value}
                name={name}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : "Voit halutessa lisätä linkin myös välittäjäyrityksen kotisivuille."}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="tarjousohjeet"
            control={control}
            rules={{
              maxLength: { value: 200, message: "Kentän oltava korkeintaan 200 merkkiä" },
            }}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <TextField
                fullWidth
                variant="standard"
                label="Tarjouksenjättöohjeet"
                value={value}
                name={name}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : "Anna tarvittaessa tarkemmat ohjeet tarjouksen jättämiseen."}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="lisatietoja"
            control={control}
            rules={{
              maxLength: { value: 5000, message: "Kentän oltava korkeintaan 5000 merkkiä" },
            }}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <TextField
                multiline
                fullWidth
                rows={5}
                variant="standard"
                label="Kuvaus"
                value={value}
                name={name}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : "Esittele tässä omin sanoin myytävänä oleva kohde (max 5000 merkkiä)."}
              />
            )}
          />
        </Grid>
      </Grid>
    </Container>
  );
}