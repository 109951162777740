import React from 'react';

import { Alert, AlertTitle } from '@mui/material';
import { Container } from '@mui/material';

const containerStyle = {
  marginTop: (theme) => theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

export default function RegistrationFailure() {

  return (
    <Container maxWidth="xs" sx={containerStyle}>
      <Alert severity="error">
        <AlertTitle>Rekisteröityminen epäonnistui</AlertTitle>
        Ole hyvä ja yritä myöhemmin uudelleen tai ota yhteys järjestelmänvalvojaan.
      </Alert>
    </Container>
  );
}