import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Profile from './Profile';

import { Backdrop, CircularProgress } from '@mui/material';

export default function ProfileWrapper({ setIsLoggedIn }) {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUser();

  }, [])

  const getUser = async () => {
    try {
      const response = await axios.get("/api/user/profiili")
      let profilePic = null

      if (response.data.profilePicUrl) {
        profilePic = { 'kuva_url': response.data.profilePicUrl }
      } 

      delete response.data.profilePicUrl
      setUser({...response.data, profilePic}) 

      setLoading(false)
    } catch(error) {
      console.log(error)
      navigate('/haku');
    }
  }

  return (
    <div>
      {loading?
        <Backdrop open={loading} data-testid="backdrop">
          <CircularProgress color="inherit" data-testid="progressIndicator" />
        </Backdrop>
      :
        <Profile user={user} getUser={getUser} setIsLoggedIn={setIsLoggedIn}/>
      }
    </div>
  );
}