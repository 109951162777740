import React, { useEffect, useState, useMemo } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import { Container, TextField, Button, Grid, Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Avatar } from '@mui/material';

const rootContainerStyle = {
  marginBottom: (theme) => theme.spacing(8),
};

const containerStyle = {
  marginTop: (theme) => theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const buttonContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
}

const pictureInfoContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}

const pictureContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}

const pictureInfoText = {
  textAlign: 'center'
}

const buttonStyle = {
  margin: (theme) => theme.spacing(2, 2),
  fontSize: {xs: '12px', sm: '14px'},
  width: "30%",
  padding: (theme) => theme.spacing(1),
}

const pictureButtonStyle = {
  margin: (theme) => theme.spacing(1, 1),
  fontSize: {xs: '12px', sm: '14px'},
  width: "100%"
}

export default function Profile({user, getUser, setIsLoggedIn}) {
  const [modifyMode, setModifyMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { control, handleSubmit, reset, setValue, getValues  } = useForm({
    defaultValues: useMemo(() => {
        return user
      },[user])
  });

  const navigate = useNavigate();

  useEffect(() => {
    return function cleanup() {
      const profilePicUrl = getValues("profilePicUrl")
      if (profilePicUrl instanceof File) {
        URL.revokeObjectURL(profilePicUrl);
      }
      revokeObjectUrl()
    }
  }, [])


  const cancelChanges = () => {
    revokeObjectUrl()
    reset(user)
    setModifyMode(false)
  }

  const closeConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handlePictureChange = (event, onChange) => {
    // If user cancels file selection during handling, do nothing
    if (!event.target.files[0]) {
      return
    }
    revokeObjectUrl()

    const pictureUrl = URL.createObjectURL(event.target.files[0])

    let file = event.target.files[0]
    file.kuva_url = pictureUrl

    onChange(file);
  };


  const handleRemovingPicture = () => {

    revokeObjectUrl()
    setValue("profilePic", null)
  };

  const removeUser = async () => {
    try {
      setLoading(true)
      await axios.delete("/api/user/poista")
      setIsLoggedIn(false)
      navigate('/haku');
    } catch(error) {
      console.log(error);
      setLoading(false)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true);
    
    const fData = new FormData()
    for (const key in data) {
      fData.append(key, data[key])
    }

    try {
      await axios.post("/api/user/paivita", fData)
      getUser();
      setModifyMode(false);
      setLoading(false);
    } catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  const revokeObjectUrl = () => {
    const profilePic = getValues("profilePic")

    if (profilePic instanceof File) {
      URL.revokeObjectURL(profilePic.kuva_url);
    }
  }

  return (
    <Container maxWidth="md" sx={rootContainerStyle}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="profilePic"
              control={control}
              render={({ field: { onChange, value, name} }) => (
                <Grid item container xs={12} sx={containerStyle} >
                  <Grid item xs={12} sx={pictureContainerStyle}>
                    <input accept="image/*" id="picture-file-input" type="file" disabled={!modifyMode} style={{ display: 'none' }} onChange={(event) => handlePictureChange(event, onChange)}/>
                    <label htmlFor="picture-file-input">
                      <Avatar
                        alt=""
                        src={value ? value.kuva_url : "../contactImage.png"}
                        sx={{'& img': { objectFit: 'contain' }, width: {xs: 200, sm:300}, height: {xs: 200, sm: 300}}}
                        variant='square'
                      />
                    </label>
                  </Grid>
                  <Grid item xs={12}>
                    {value ?
                    <div style={pictureInfoContainerStyle}> 
                      <Button sx={pictureButtonStyle} disabled={!modifyMode} type="button" variant="contained" color="primary" onClick={() => handleRemovingPicture(name)}>Poista profiilikuva</Button>
                    </div>
                      :
                    <div style={pictureInfoContainerStyle}>
                      <Typography color={!modifyMode ? "textSecondary" : ""} sx={pictureInfoText}>
                        Valitse profiilikuva klikkaamalla kuvaketta
                      </Typography>
                      <Typography variant='body2' color={!modifyMode ? "textSecondary" : ""} sx={pictureInfoText}>
                        (Tämä voi olla esimerkiksi yrityksesi logo)
                      </Typography>
                    </div>
                    }
                  </Grid>
                </ Grid >
              )}
            />
          </Grid>
          <Grid item container xs={12} spacing={2} >
            <Grid item xs={6}>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField fullWidth disabled label="Sähköposti*" name="email" variant="outlined" value={value} onChange={onChange} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: 'Syötä etunimi',
                  maxLength: { value: 50, message: "Etunimen oltava korkeintaan 50 merkkiä" },
                }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField fullWidth disabled={!modifyMode} label="Etunimi*" name={name} variant="outlined" value={value} onChange={onChange} autoFocus error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: 'Syötä sukunimi',
                  maxLength: { value: 50, message: "Sukunimen oltava korkeintaan 50 merkkiä" },
                }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField fullWidth disabled={!modifyMode} label="Sukunimi*" name={name} variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="phoneNum"
                control={control}
                rules={{ maxLength: { value: 15, message: "Puhelinnumeron oltava korkeintaan 15 merkkiä" } }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField fullWidth disabled={!modifyMode} label="Puhelinnumero" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="city"
                control={control}
                rules={{ maxLength: { value: 50, message: "Kaupungin nimen oltava korkeintaan 50 merkkiä" } }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField fullWidth disabled={!modifyMode} label="Kaupunki" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="address"
                control={control}
                rules={{ maxLength: { value: 50, message: "Osoitteen oltava korkeintaan 50 merkkiä" } }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField fullWidth disabled={!modifyMode} label="Osoite" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null}/>
                )}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="contactInfo"
                control={control}
                render={({ field: { onChange, value, name }}) => (
                  <TextField fullWidth multiline rows={3} disabled={!modifyMode} label="Vapaa tekstikenttä" placeholder="Vapaa tekstikenttä. Kerro esim paras aika ja tapa ottaa yhteyttä tai mainosta yritystäsi!" name={name} variant="outlined" value={value} onChange={onChange}/>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {!modifyMode &&
        <div style={buttonContainerStyle}> 
          <Button sx={buttonStyle} type="button" variant="contained" color="primary" onClick={() => {setModifyMode(true)}}>
            MUOKKAA
          </Button>
          <Button sx={buttonStyle} variant="contained" color="secondary" onClick={() => {setOpenConfirmDialog(true)}}  >
            POISTA KÄYTTÄJÄ
          </Button>
        </div>
        }
        {modifyMode &&
          <div style={buttonContainerStyle}> 
            <Button sx={buttonStyle} type="submit" variant="contained" color="primary" >
              TALLENNA
            </Button>
            <Button sx={buttonStyle} variant="contained" color="secondary" onClick={cancelChanges}  >
              PERUUTA
            </Button>
          </div>
        }
      </form>
      <Dialog open={openConfirmDialog} onClose={closeConfirmDialog} >
        <DialogTitle variant='h5'>
          Haluatko varmasti poistaa käyttäjän?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Poistamalla käyttäjän poistat myös kaikki käyttäjän ilmoitukset.
            Ilmoitusten tietoja ei voida jälkikäteen palauttaa.
            Oletko varma että haluat poistaa käyttäjätilin?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={removeUser} sx={buttonStyle} variant="contained">Poista käyttäjä</Button>
          <Button onClick={closeConfirmDialog} autoFocus sx={buttonStyle} variant="contained">Peruuta</Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}