import React, { useState, useEffect } from 'react';

import axios from "axios";

import { Container, Grid, List, ListItem, Button, Typography, Avatar, Collapse } from '@mui/material';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import WorkIcon from '@mui/icons-material/Work';

import ContactForm from './ContactForm';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const listStyle = {
  width: '100%',
}

const innerListStyle = {
  marginTop: (theme) => theme.spacing(2),
}

const listItemStyle= {
  borderRadius: '25px',
  boxShadow: 5,
  marginBottom: (theme) => theme.spacing(4),
  minHeight: {xs: 160, sm: 180, md: 220},
}

const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: "center",
  height: '100%'
}

const openingRowStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: "center",
  justifyContent: "center",
  height: {xs: 140, sm: 160, md: 200}
}

const imageContainerStyle = {
  height: '100%',
  width: '100%'
}

const imageStyle = {
  '& img': {
    objectFit: 'fill',
  },
  width: '100%',
  height: '100%',
  borderRadius: '25px'
}

const separatedRowStyle = {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: "end",
}

const columnStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: '100%'
}

const spaceGridStyle = {
  display: {xs: 'none', sm: 'block'}
}

const headerTextStyle = {
  color:'black',
  hyphens: 'auto',
  textAlign: {xs:'center', sm:'left'},
  fontSize: {xs: '12px', sm: '16px', md: '20px', lg: '24px'}
}

const companyTextStyle = {
  color:'black',
  textAlign: {xs:'center', sm:'right'},
  fontSize: {xs: '12px', sm: '16px', md: '20px', lg: '24px'}
}

const textStyles = {
  fontSize: {xs: '12px', sm: '16px'},
  width:'100%'
}

const iconStyle = {
  fontSize: { xs: '18px', sm: '16px' },
  marginBottom: { xs: '-5px', sm: '-2px' },
  marginRight: '6px'
}

const buttonStyle = {
  width: {xs: '100%', sm: '80%', md: '50%'},
  fontSize: { xs: '12px', sm: '16px' },
}

const contactButtonStyle = {
  width: {xs: '100%', sm: '50%', md: '30%'},
  padding: {xs: '5px', sm: '10px'},
  marginBottom: {xs: '10px', sm: '20px'},
  fontSize: { xs: '12px', sm: '16px' },
}

export default function Services({palvelulista}) {

  const [selectedYritys, setSelectedYritys] = useState('')
  const [selectedPalvelu, setSelectedPalvelu] = useState('')
  const [openedListItems, setOpenedListItems] = useState([])
  const [openContactForm, setOpenContactForm] = useState(false)

  useEffect(() => {

    // Lets initialize the openedListItems array so that every list item is closed by default
    setOpenedListItems(palvelulista.map((palvelu) => { return { id: palvelu.id, open: false }}))
  }, [])

  const handleChangeVisible = (id) => {
    const newOpenedListItems = openedListItems.map(obj => {
      if (obj.id === id) {
          if (obj.open === false) {
            increaseViewCount(id)
          }
          return { id: obj.id, open: !obj.open }; // Create a new object with the modified field
      }
      return obj; // Return the object unchanged if it doesn't match the condition
    });
    
    setOpenedListItems(newOpenedListItems)
  }

  const handleOpenContactForm = (palvelu) => {
    setSelectedPalvelu(palvelu.palvelunimi)
    setSelectedYritys(palvelu.id)
    setOpenContactForm(true)
  }

  const increaseViewCount = (id) => {
    axios.get("/api/yritys/lisaa-nayttokerta/" + id)
  }

  const increaseClickCount = (id) => {
    axios.get("/api/yritys/lisaa-klikkaus/" + id)
  }

  return (
    <Container maxWidth="lg" sx={containerStyle}>
      <List sx={listStyle} name="palvelulista">
        {palvelulista?.map((palvelu) => {
          return (
            <ListItem key={palvelu.id} sx={listItemStyle}>
              <Grid container sx={rowStyle}>
                <Grid item container xs={12} spacing={2} sx={openingRowStyle}>
                  <Grid item xs={6} sm={3.5} sx={imageContainerStyle}>
                    <Avatar
                      src={palvelu.logoUrl ? palvelu.logoUrl : "../image_placeholder.jpeg"}
                      alt="myynti-ilmoituksen kuva"
                      sx={imageStyle}
                      loading="lazy"
                    />
                  </Grid>
                  <Grid item xs={0} sm={0.5} sx={spaceGridStyle}></Grid>
                  <Grid item container xs={6} sm={7.5} sx={columnStyle}>
                    <Grid item xs={12}>
                      <Typography sx={headerTextStyle} variant="body1">
                        {palvelu.palvelunimi === "Metsäkiinteistövälitys" ?
                         <b>Metsäkiinteistö&shy;välitys</b>
                         :
                         <b>{palvelu.palvelunimi}</b>
                        }
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} sx={separatedRowStyle}>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={companyTextStyle} variant="body1">{palvelu.nimi}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button variant="contained" sx={buttonStyle} onClick={() => {handleChangeVisible(palvelu.id)}} >
                          {openedListItems.find(obj => obj.id === palvelu.id)?.open ? "Sulje" : "Lue lisää"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Collapse in={openedListItems.find(obj => obj.id === palvelu.id)?.open} timeout="auto">
                    <List sx={innerListStyle}>
                      <ListItem>
                        <Typography variant="body1" sx={textStyles}  dangerouslySetInnerHTML={{ __html: palvelu.kuvaus }} />
                      </ListItem>
                      <ListItem>
                        <Typography variant="body1" sx={textStyles}><MailOutlineIcon sx={iconStyle}/><a href={`mailto:${palvelu.email}`}>{palvelu.email }</a></Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body1" sx={textStyles}><PhoneIcon sx={iconStyle}/> {palvelu.phoneNum } </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body1" sx={textStyles}><LocationOnIcon sx={iconStyle}/> {palvelu.osoite } </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body1" sx={textStyles}><LanguageIcon sx={iconStyle}/><a href={palvelu.linkki} onClick={() => {increaseClickCount(palvelu.id)}} target="_blank" rel="noreferrer">{palvelu.linkki }</a> </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body1" sx={textStyles}><WorkIcon sx={iconStyle}/>Y-tunnus: {palvelu.ytunnus} </Typography>
                      </ListItem>
                    </List>
                    <Container sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      <Button variant="contained" sx={contactButtonStyle} onClick={() => {handleOpenContactForm(palvelu)}} >
                        Ota yhteyttä
                      </Button>
                    </Container>
                  </Collapse>
                </Grid>
              </Grid>
            </ListItem>
          )
        })}
      </List>
      <ContactForm open={openContactForm} setOpen={setOpenContactForm} id={selectedYritys} palvelu={selectedPalvelu} />
    </Container>
  );
}