import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

import {Dialog, Button, TextField, Typography, Autocomplete, MenuItem, Grid, Container, Backdrop, CircularProgress, Alert, AlertTitle } from '@mui/material';

const containerStyle = {
    backgroundColor: "white",
    marginTop: (theme) => theme.spacing(2),
    padding: {xs: '16px', sm: '64px'},
    borderRadius: {xs: '0', sm: '20%'}
  };

  const headerStyle = {
    fontSize: {xs: '16px', sm: '28px'}
  }

export default function ContactForm({open, setOpen, id, palvelu}) {
    const { handleSubmit, control, reset } = useForm({ defaultValues: {
        phoneNum: "",
        email: "",
        kunta: "",
        kiinteistotunnus: "",
        viesti: ""
    }});

    const [kuntaOptions, setKuntaOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [sendSuccess, setSendSuccess] = useState(false);
    const [sendFailure, setSendFailure] = useState(false);

    useEffect(() => {
        getKunnat()
      }, []);
    

    const getKunnat = async () => {
        try {
          const response = await axios.get("/api/data/kunnat")
          setKuntaOptions(response.data)
        } catch(error) {
          console.log(error)
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = async (data) => {
        setLoading(true)

        data.id = id
        data.palvelu = palvelu
    
        try {
          await axios.post("/api/yhteydenotto/luo", data)
          setSendSuccess(true);
          reset();
          setLoading(false)
          setTimeout(() => {
            setSendSuccess(false)
            handleClose()
          }, 3000);
        } catch(error) {
          console.log(error);
          setSendFailure(true)
          setLoading(false)
          setTimeout(() => {
            setSendFailure(false)
          }, 3000);
        }
      }

  return (
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
      >
        <Container maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
            {(!sendSuccess && !sendFailure) &&
                <Grid container spacing={2} sx={containerStyle}>
                    <Grid item xs={12} >
                        <Typography variant="h5" align="center" sx={headerStyle}>
                            Lisää yhteystietosi niin olemme sinuun yhteydessä
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="phoneNum"
                            control={control}
                            rules={{required: 'Syötä puhelinnumero', maxLength: { value: 15, message: "Puhelinnumeron oltava korkeintaan 15 merkkiä" } }}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <TextField fullWidth label="Puhelinnumero*" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                            maxLength: { value: 50, message: "Sähköpostin oltava korkeintaan 50 merkkiä" },
                            pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: "Tarkista sähköposti" }}}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <TextField fullWidth label="Sähköposti" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Controller
                            name="kunta"
                            control={control}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <Autocomplete
                                    options={kuntaOptions}
                                    clearOnEscape
                                    name={name}
                                    value={value}
                                    onChange={(_, value) => {onChange(value)}}
                                    renderInput={(params) =>
                                        <TextField {...params} label="Kunta" name={name} variant="outlined" error={!!error} helperText={error ? error.message : null} />
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="kiinteistotunnus"
                            control={control}
                            rules={{
                                pattern: { value: /^[0-9]{1,3}-[0-9]{1,3}-[0-9]{1,4}-[0-9]{1,4}$/, message: "Tarkista kiinteistötunnus" }
                            }}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                <TextField label="Kohteen kiinteistötunnus" variant="outlined" error={!!error} helperText={error ? error.message : null}
                                value={value} name={name} onChange={onChange} fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Controller
                            name="viesti"
                            control={control}
                            rules={{
                                maxLength: { value: 500, message: "Kentän oltava korkeintaan 500 merkkiä" },
                            }}
                            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <TextField
                                multiline
                                rows={4}
                                fullWidth
                                variant="outlined"
                                label="Viesti"
                                value={value}
                                name={name}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : "Kuvaa tarvittaessa tilannettasi tarkemmin"}
                            />
                            )}
                        />
                    </Grid>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={6}>
                        <Button type="submit" fullWidth variant="contained" color="primary">
                        Lähetä
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={handleClose} fullWidth variant="contained" color="primary">
                        Peruuta
                        </Button>
                    </Grid>
                </Grid>
                <Grid/>
                </Grid>
            }
            </form>
            {sendSuccess &&
            <Alert severity="success">
                <AlertTitle>Yhteydenotto lähetetty onnistuneesti</AlertTitle>
            </Alert>
            }
            {sendFailure &&
            <Alert severity="error">
                <AlertTitle>Yhteydenoton lähettäminen epäonnistui</AlertTitle>
                Yritä myöhemmin uudelleen tai ole yhteydessä järjestelmänvalvojaaan.
            </Alert>
            }
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
      </Dialog>
  );
}