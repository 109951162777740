import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

import {Button, TextField, Grid, Container, CircularProgress, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { Height } from '@mui/icons-material';

const containerStyle = {
  marginTop: (theme) => theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const buttonContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
  

const buttonStyle = {
    width: {xs: '90%', sm: "70%", md: "50%"},
    align: 'center',
}

const linkStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
}

const circularProgressStyle = {
  margin: (theme) => theme.spacing(4)
}

const errorStyle = {
  marginTop: (theme) => theme.spacing(2),
  padding: "8px 16px",
}

export default function ContactSubmit() {
  const [loading, setLoading] = useState(false);
  const [sentSuccess, setSentSuccess] = useState(false);
  const [sentFailure, setSentFailure] = useState(false);
  const [sentFailureMessage, setSentFailureMessage] = useState('');
  const { handleSubmit, control } = useForm({ defaultValues: { email: "", phoneNum: "", contactAccepted: true}});

  const [userTermsAgreed, setUserTermsAgreed] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await axios.post("/api/data/laheta-metsamyyntiopas", data)
      setSentSuccess(true)
      setLoading(false)
    } catch(error) {
      setSentFailure(true)
      if(error.response.data.message) {
        console.log(error.response.data.message)
        setSentFailureMessage(error.response.data.message);
      } else {
        setSentFailureMessage('Yritä myöhemmin uudelleen tai ole yhteydessä järjestelmänvalvojaan.')
      }
      setLoading(false)
      setTimeout(() => {
        setSentFailure(false)
        setSentFailureMessage('')
      }, 5000);
    }
  }

  return (
    <Container sx={containerStyle} maxWidth="md">
      {loading?
          <CircularProgress color="primary" sx={circularProgressStyle}/>
      :
      <div>
        {!sentSuccess &&
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} >
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: 'Syötä sähköposti',
                    maxLength: { value: 50, message: "Sähköpostin oltava korkeintaan 50 merkkiä" },
                    pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: "Tarkista sähköposti" }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField fullWidth label="Sähköposti" name="email" variant="outlined" value={value} onChange={onChange} autoFocus error={!!error} helperText={error ? error.message : null} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <Controller
                    name="phoneNum"
                    control={control}
                    rules={{ 
                        required: 'Syötä puhelinnumero',
                        maxLength: { value: 15, message: "Puhelinnumeron oltava korkeintaan 15 merkkiä" } 
                    }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <TextField fullWidth label="Puhelinnumero" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                    )}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={linkStyle}>
                <FormControlLabel sx={{ alignItems: 'flex-start' }}
                    control={ <Checkbox value={userTermsAgreed} color="primary" name="termsAccepted" onChange={e => setUserTermsAgreed(e.target.checked)} sx={{marginTop: -1}} />}
                    label={ <Typography variant="body2"> Olen lukenut palvelun <a href='/kayttoehdot' target="_blank" rel="noreferrer">käyttöehdot</a> ja hyväksyn ne.</Typography> } 
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={linkStyle}>
                <Controller
                    name="contactAccepted"
                    control={control}
                    render={({ field: { onChange, value, name }}) => (
                        <FormControlLabel sx={{ alignItems: 'flex-start' }}
                            control={ <Checkbox value={value} color="primary" name={name} onChange={onChange} defaultChecked={true} sx={{marginTop: -1}} />}
                            label={ <Typography variant="body2">Yhteystietoni saa välittää Nettimetsän yhteistyökumppani kiinteistönvälittäjille, jotka voivat olla minuun yhteydessä metsän myyntiin liittyen.</Typography> } 
                        />
                    )}
                />
              </Grid>
              <Grid item xs={12} sx={buttonContainerStyle}> 
                <Button disabled={!userTermsAgreed} type="submit" variant="contained" color="primary" sx={buttonStyle}>
                  Lähetä metsätilan myyjän opas
                </Button>
              </Grid>
            <Grid/>
            </Grid>
            {sentFailure &&
              <Alert severity="error" sx={errorStyle}>
                <AlertTitle>Tiedoston lähettäminen epäonnistui</AlertTitle>
                {sentFailureMessage}
              </Alert>
            }
          </form>
        }
        {sentSuccess &&
          <Alert  severity="success">
            <AlertTitle>Tiedoston lähettäminen onnistui</AlertTitle>
            Metsätilan myyjän opas on lähetetty sähköpostiisi.
          </Alert>
        }
      </div>
      }
    </Container>
  );
}