import React from 'react';
import { Container, Typography } from '@mui/material';

const containerStyle = {
  marginBottom: (theme) => theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const headerStyle = {
    marginTop: (theme) => theme.spacing(4),
    marginBottom: (theme) => theme.spacing(4),
    alignItems: 'center',
    overflowWrap: 'anywhere',
  };

  const smallHeaderStyle = {
    marginTop: (theme) => theme.spacing(2),
    marginBottom: (theme) => theme.spacing(2),
    width:'100%',
    align: 'left',
    overflowWrap: 'anywhere',
  };

const textStyle = {
    fontSize: '16px',
    width: '100%',
};

const listStyle = {
    marginBottom: 0,
};

export default function Instructions() {

  return (
    <Container maxWidth="lg" sx={containerStyle}>
        <Typography variant='h4' sx={headerStyle}>Ohjeet ja säännöt käyttäjille</Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Myynti-ilmoituksesta ei peritä palvelussa maksua ilmoittajilta. 
            Tämä koskee sekä kiinteistönvälittäjiä että yksityisiä myyjiä. 
            Myynti-ilmoituksen tekoa varten ilmoittajan tulee luoda nettimetsään käyttäjä, jonka voi rekisteröidä palveluun tästä <a href="./rekisteroityminen">linkistä.</a>
        </Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Käyttäjä voi tehdä palvelussa myynti-ilmoituksen kokonaisesta metsätilakiinteistöstä, metsätilakiinteistön määräalasta, yhteismetsäosuudesta tai tontista. 
            Ilmoitukset ovat luonteeltaan aina perusilmoituksia, eli kauppa ja maksu hoidetaan palvelun ulkopuolella myyjän ja ostajan sopimalla tavalla. 
            Kaupoista on hyvä tehdä aina kauppakirja.
        </Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Myynti-ilmoitukset vanhenevat neljässä kuukaudessa, mikäli käyttäjä ei itse poista ilmoitusta palvelusta ennen sitä.
        </Typography>
        <Typography variant='h4' sx={headerStyle}>Myynti-ilmoituksen tekeminen Nettimetsässä</Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Myynti-ilmoitukseen on ilmoitettava metsätilan sijainti, maakunta sekä kunta. 
            Myynti-ilmoitukseen on ilmoitettava myös metsämaan koko sekä muun maan koko hehtaareina. 
            Voit määrittää myynti-ilmoitukseen kiinteän hintapyynnön kohteesta tai vaihtoehtoisesti voit asettaa kauppatavaksi tarjouskaupan. 
            Käyttäjän pitää ilmoittaa myynti-ilmoitusta tehdessä myös myytävän kohteen kiinteistötunnus sekä kiinteistön nimi. 
            Käyttäjän on ilmoitettava myynti-ilmoitukseen myös metsätilakiinteistön puun määrä. 
            Löydät tämän esimerkiksi metsään.fi –palvelusta, metsätilasuunnitelmasta tai myyntiä varten tehdystä metsätila-arviosta. 
            Täytä myynti-ilmoitukseen myös metsäarvion ajankohta. Mikäli myynti-ilmoituksen tekijänä on yritys, yrityksen tiedot tulee lisätä myynti-ilmoitukseen.
        </Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Puustotiedot-osuuden täyttäminen onnistuu palvelussa kätevimmin käyttämällä ”syötä tiedot XML-tiedostosta” -toimintoa. 
            Voit hakea myytävän metsätilakiinteistön XML-tiedoston Foresta-metsäjärjestelmästä seuraamalla järjestelmän <a href="https://www.foresta.fi/ohjeet/Foresta_kayttoohje.pdf" target="_blank" rel="noreferrer noopener">käyttöohjeita</a> (Kappaleet 8.1 ja 8.2).
        </Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Kun XML-tiedosto on viety nettimetsään, ohjelma täyttää automaattisesti metsätilan puutavaralaji-, kehitysluokka-, sekä kasvupaikkatyyppijakauman. 
            Ilmoittajan tehtäväksi jää tämän jälkeen ainoastaan yleisten puustotietojen täyttäminen.
        </Typography>
        <Typography variant='h4' sx={headerStyle}>Palvelun ilmoittaminen Nettimetsään</Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Voit ilmoittaa palvelusi Nettimetsään, josta potentiaaliset asiakkaat tavoittavat sinut helposti. 
            Tarjouksen palvelun ilmoittamisesta voit pyytää ylläpidolta. Tarjouksen yhteydessä saat tarkemmat ohjeet palvelun ilmoittamisesta. 
            Tarjouksen voit pyytää sähköpostiosoitteesta <a href='mailto:yllapito@nettimetsa.fi'>yllapito@nettimetsa.fi</a> tai numerosta 0400504124. 
        </Typography>
        <Typography variant='h4' sx={headerStyle}>Nettimetsän ilmoitusten valvonta</Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Nettimetsällä on oikeus poistaa asiattomat ilmoitukset ja ilmoittajat palvelusta ilman ennakkovaroitusta. Asiatonta ilmoittamista on esimerkiksi:
            <ol style={listStyle}>
                <li>Kuva ei vastaa myytävää kohdetta</li>
                <li>Ilmoitettu hinta ei pidä paikkaansa</li>
                <li>Väärä puhelinnumero</li>
                <li>Siveettömyys sekä yleinen asiattomuus</li>
            </ol>
        </Typography>
        <Typography variant='h4' sx={headerStyle}>Kuvat</Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Nettimetsään jätettävän aineiston kuten logojen, bannerien, sekä metsätilojen kuvien tekijänoikeuksista vastaa aina ilmoituksen jättäjä. 
            Jesa Group Oy palveluntuottajana ei ota vastuuta palveluun jätettävästä aineistosta, eikä niihin liittyvistä mahdollisista jälkiseuraamuksista. 
            Jos siis käytät jonkun muun omistamia kuvia, hanki niihin lupa etukäteen.
        </Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Kuvan lataaminen ilmoitukseen on edellytys ilmoituksen jättämiselle. Myytävälle metsätilalle on jätettävä vähintään yksi yleiskuva. 
            Huomioithan, että liian suuret kuvatiedostot voivat aiheuttaa vikatilanteen. Käytäthän vain alle 15 megatavun -kokoisia kuvatiedostoja. 
            Muista myös ilmoitusta tehdessäsi odottaa rauhassa, että kuvatiedostot ovat latautuneet palveluun kokonaan.
        </Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Hyvät kuvat ja riittävä informaatio myytävänä olevasta kohteesta ovat avain onnistuneille kaupoille.
        </Typography>
        <Typography variant='h4' sx={headerStyle}>Ilmoituksen muuttaminen ja poistaminen Nettimetsässä</Typography>
        <Typography variant='h5' sx={smallHeaderStyle}><b>Myynti-ilmoitukset</b></Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Seuraavilla ohjeilla voit tehdä muutoksia tai poistaa ilmoituksia Nettimetsässä.
            <ol>
                <li>Kirjaudu sisään nettimetsään</li>
                <li>Klikkaa ”Omat ilmoitukset”</li>
                <li>Klikkaa "Muokkaa" linkkiä muokataksesi ilmoituksen tietoja</li>
                <li>Klikkaa "Poista” -linkkiä, jos haluat poistaa ilmoituksesi tai merkitä sen myydyksi</li>
            </ol>
            Ongelmia? Ota yhteys asiakaspalveluun  <a href="./info">yhteystiedot</a> -lomakkeelta.
        </Typography>
        <Typography variant='h5' sx={smallHeaderStyle}><b>Palveluilmoitukset</b></Typography>
        <Typography variant="body2" paragraph sx={textStyle}>
            Voit muuttaa palveluilmoituksen sisältöä olemalla yhteydessä sähköpostiin <a href='mailto:yllapito@nettimetsa.fi'>yllapito@nettimetsa.fi</a> tai numerosta 0400504124.
        </Typography>
    </Container>
  );
}