import React, { useState } from 'react';
import { Controller } from "react-hook-form";

import {Container, Typography, IconButton, Button, Alert, AlertTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: {xs: 300, sm: 600, md: 800 },
}

const buttonStyle = {
  margin: (theme) => theme.spacing(2, 2),
  width: (theme) => theme.spacing(24)
}

const alertStyle = {
  marginBottom: (theme) => theme.spacing(2)
}

export default function Liitteet({control, getValues, setValue}) {

  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleFileUpload = (e) => {
  
    if (!e.target.files) {
      return;
    }

    const attachments = getValues("liitteet")
    let files = []


    // Converting first the FileList to regular array before looping through it.
    // Checking that the amount of files will not be over 20
    Array.from(e.target.files).forEach(file => {
      if (files.length + attachments.length === 20) {
        setErrorText("Lisää korkeintaan 20 liitetiedostoa")
        setShowError(true);
        setTimeout(() => {
          setErrorText("")
          setShowError(false);
        }, 5000);
        return;
      }
      if (file.size > 31457280) { // This is 30MB which should be the limit for the size.
        setErrorText("Tiedostojen koko saa olla korkeintaan 30MB")
        setShowError(true);
        setTimeout(() => {
          setErrorText("")
          setShowError(false);
        }, 5000);
        return;
      }
      if (!attachments.some(liite => liite.liite_name === file.name)) {
        file.liite_name = file.name
        files.push(file)
      }
    });

    setValue("liitteet", attachments.concat(files))
  }

  const removeFile = (fileIndex) => {
    const attachments = getValues("liitteet")

    setValue("liitteet", attachments.filter((item, index) => {
      return index !== fileIndex
    }))
  }

  return (
    <Container sx={containerStyle}>
      <input id="attachment-file-input" multiple type="file" name="liitteet" style={{ display: 'none' }} onChange={handleFileUpload}/>
      <label htmlFor="attachment-file-input">
        <Button sx={buttonStyle} component="span" variant="contained" >
          Lisää liitteitä
        </Button>
      </label>
      {showError &&
        <Alert severity="error" sx={alertStyle}>
          <AlertTitle>Liitteiden lisääminen epäonnistui</AlertTitle>
          {errorText}
        </Alert>
      }
      <Controller
        name="liitteet"
        control={control}
        render={({ field: { value } }) => (
          <ul name="liitelista">
            {value.map((file, i) => (
              <li key={file.liite_name}>
                <Typography sx={{maxWidth: '350px'}}> {file.liite_name}
                <IconButton
                  onClick={() => {removeFile(i)}}
                  size="large">
                    <CloseIcon/>
                </IconButton>
                </Typography>
              </li>
            ))}
          </ul>
        )}
      />
    </Container>
  );
}