import React, {useState, useEffect} from 'react';

import {
  Grid, List, ListItem, Container, Avatar, Typography, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';

import { formatNumberWithSeparator } from '../../utils';

const containerStyle = {
  marginTop: (theme) => theme.spacing(2),
  marginBottom: (theme) => theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const listItemStyle = {
  borderRadius: '25px',
  boxShadow: 5,
  marginBottom: (theme) => theme.spacing(2)
}

const columnStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: {xs:'center', sm:'flex-start'},
  minHeight: {xs: 100, sm: 160, md: 180, lg: 200}
}

const imageStyle = {
  objectFit: 'contain',
  width: {xs: 120, sm: 180, md: 200, lg: 220},
  height: {xs: 100, sm: 160, md: 180, lg: 200},
  borderRadius: '25px',
}

const textStyle = {
  color:'black',
  textAlign: {xs:'center', sm:'left'},
  fontSize: {xs: '12px', sm: '16px'}
}

const smallerTextStyle = {
  color:'black',
  fontSize: {xs: '12px', sm: '14px'},
}

const smallerTextCenterStyle = {
  color:'black',
  fontSize: {xs: '12px', sm: '14px'},
  textAlign: 'center'
}

const mobileVisibleStyle = {
  display: {xs: 'block', sm: 'none'}
}

const mobileHiddenStyle = {
  display: {xs: 'none', sm: 'block'}
}

const mobileHiddenHeaderStyle = {
  display: {xs: 'none', sm: 'flex'},
  width:'100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}

const gridStyle = {
  justifyContent: "center",
  alignItems: "center"
}

const listButtonsStyle = {
  width: '100%',
  fontSize: {xs: '12px', sm: '14px'},
  padding: (theme) => theme.spacing(1),
}

const createNewButtonStyle = {
  width: {xs: '60%', sm: "40%", md: "30%"},
  fontSize: {xs: '12px', sm: '14px'},
  marginBottom: (theme) => theme.spacing(2),
}

const circularProgressStyle = {
  margin: (theme) => theme.spacing(4)
}

export default function Ilmoitukset({ilmoitukset, createIlmoitus, modifyIlmoitus, removeIlmoitus, loading, setLoading}) {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [removingIlmoitus, setRemovingIlmoitus] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  const handleInitialRemoveTry = (ilmoitus_id) => {
    setRemovingIlmoitus(ilmoitus_id)
    setOpenConfirmDialog(true)
  }

  const handleRemove = async () => {
    setOpenConfirmDialog(false)
    setLoading(true)
    await removeIlmoitus(removingIlmoitus)
    setLoading(false)
  }

  const closeConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <Container sx={containerStyle}>
      {loading ?
        <CircularProgress color="primary" sx={circularProgressStyle}/>
      :
        <List name="ilmoituslista" sx={{width:'100%'}}>
          {ilmoitukset?.map((ilmoitus) => {
            return (
              <ListItem key={ilmoitus.ilmoitus_id} sx={listItemStyle}>
                <Grid container item spacing={2} xs={12} sx={gridStyle}>
                  <Grid item container xs={4.5} sm={4} md={3} lg={3} sx={columnStyle}>
                    <Grid item xs={12} sx={mobileVisibleStyle}>
                      <Typography sx={smallerTextCenterStyle} variant="body2">
                        <b>{ilmoitus.tyyppi}</b> {ilmoitus.tyyppi !== 'Yhteismetsäosuus' ? ilmoitus.kiinteistonimi : ilmoitus.yhteismetsa_nimi}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <Avatar
                        alt=""
                        src={ilmoitus.kuva_url ? ilmoitus.kuva_url : "../image_placeholder.jpeg"}
                        sx={imageStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sx={mobileVisibleStyle}>
                      <Typography sx={smallerTextCenterStyle} variant="body2">{ilmoitus.tarjouskauppa === 'true' ? `Tarjous jätettävä viimeistään ${ilmoitus.tarjouskauppa_paattyy}` : ""}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={4.5} sm={6} md={7} lg={7} sx={columnStyle}>
                    <Grid item xs={12} sx={mobileHiddenHeaderStyle}>
                      <Typography sx={smallerTextStyle} variant="body2">
                        <b>{ilmoitus.tyyppi}</b> {ilmoitus.tyyppi !== 'Yhteismetsäosuus' ? ilmoitus.kiinteistonimi : ilmoitus.yhteismetsa_nimi}
                      </Typography>
                      <Typography sx={smallerTextStyle} variant="body2">
                        <b>Näyttökertojen määrä:</b> {ilmoitus.view_count}
                      </Typography>
                    </Grid>
                    {ilmoitus.tyyppi !== 'Yhteismetsäosuus' ?
                      <Grid item container xs={12}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <Typography sx={textStyle} variant="body1"><b>Hinta</b></Typography>
                          <Typography sx={textStyle} variant="body1">{formatNumberWithSeparator(ilmoitus.hintapyynto)} €</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} >
                          <Typography sx={textStyle} variant="body1"><b>Pinta-ala</b></Typography>
                          <Typography sx={textStyle} variant="body1">{(Math.round(ilmoitus.koko*100)/100).toFixed(2)} ha</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} sx={{display: ilmoitus.puu_maara ? 'block' : 'none' }}>
                          <Typography sx={textStyle} variant="body1"><b>Kuutiot</b></Typography>
                          <Typography sx={textStyle} variant="body1">{ilmoitus.puu_maara + " m³"}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} >
                          <Typography sx={textStyle} variant="body1"><b>Sijainti</b></Typography>
                          <Typography sx={textStyle} variant="body1">{ilmoitus.maakunta}, {ilmoitus.kunta}</Typography>
                        </Grid>
                      </Grid>
                    :
                      <Grid item container xs={12} >
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                          <Typography sx={textStyle} variant="body1"><b>Hinta</b></Typography>
                          <Typography sx={textStyle} variant="body1">{formatNumberWithSeparator(ilmoitus.hintapyynto)} €</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={9} lg={9} >
                          <Typography sx={textStyle} variant="body1"><b>Myynnissä olevat osuudet</b></Typography>
                          <Typography sx={textStyle} variant="body1">{ilmoitus.yhteismetsa_osuudet}/{ilmoitus.yhteismetsa_kokonaisosuudet}</Typography>
                        </Grid>
                      </Grid>
                    }
                    <Grid item xs={12} sx={mobileHiddenStyle}>
                      <Typography sx={smallerTextStyle} variant="body2">{ilmoitus.tarjouskauppa === 'true' ? `Tarjous jätettävä viimeistään ${ilmoitus.tarjouskauppa_paattyy}` : ""}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={3} sm={2} md={2} lg={2} spacing={2}>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" sx={listButtonsStyle} onClick={() => {modifyIlmoitus(ilmoitus.ilmoitus_id)}} >
                        Muokkaa
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="secondary" sx={listButtonsStyle} onClick={() => {handleInitialRemoveTry(ilmoitus.ilmoitus_id)}} >
                        Poista
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
            )
          })}
        </List>
      }
      <Button variant="contained" color="primary" sx={createNewButtonStyle} onClick={() => {createIlmoitus()}} >
        Luo uusi ilmoitus
      </Button>
      <Dialog open={openConfirmDialog} onClose={closeConfirmDialog} >
        <DialogTitle>
          Haluatko varmasti poistaa ilmoituksen?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ilmoitus poistetaan pysyvästi eikä tietoja voi enää palauttaa.
            Haluatko varmasti poistaa ilmoituksen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleRemove}>Poista ilmoitus</Button>
          <Button variant="contained" onClick={closeConfirmDialog} autoFocus>Peruuta</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}