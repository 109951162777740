import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

import {Button, TextField, Grid, Typography, Container, Link, CircularProgress } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

const containerStyle = {
  marginTop: (theme) => theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const circularProgressStyle = {
  margin: (theme) => theme.spacing(4)
}

const errorStyle = {
  marginTop: (theme) => theme.spacing(2),
  padding: "8px 16px",
}

export default function KohdevahtiDelete() {
  const [loading, setLoading] = useState(false);
  const [sentSuccess, setSentSuccess] = useState(false);
  const [sentFailure, setSentFailure] = useState(false);
  const [sentFailureMessage, setSentFailureMessage] = useState('');
  const { handleSubmit, control } = useForm({ defaultValues: { email: "", password: ""}});

  const onSubmit = async (data) => {
    setLoading(true)

    console.log(data)
    try {
      await axios.post("/api/kohdevahti/poista", data)
      setSentSuccess(true)
      setLoading(false)
      setTimeout(() => {
        setSentSuccess(false)
      }, 3000);
    } catch(error) {
      setSentFailure(true)
      if(error.response.data.message) {
        console.log(error.response.data.message)
        setSentFailureMessage(error.response.data.message);
      } else {
        setSentFailureMessage('Yritä myöhemmin uudelleen tai ole yhteydessä järjestelmänvalvojaan.')
      }
      setLoading(false)
      setTimeout(() => {
        setSentFailure(false)
        setSentFailureMessage('')
      }, 3000);
    }
  }

  return (
    <Container sx={containerStyle} maxWidth="xs">
      {loading?
          <CircularProgress color="primary" sx={circularProgressStyle}/>
      :
      <div>
        {!sentSuccess &&
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Typography component="body1" align="center" color="textSecondary">Syötä sähköposti ja salasana kohdevahdin poistamiseksi</Typography>
              </Grid>
              <Grid item xs={12} >
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: 'Syötä sähköposti',
                    maxLength: { value: 50, message: "Sähköpostin oltava korkeintaan 50 merkkiä" },
                    pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: "Tarkista sähköposti" }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          fullWidth 
                          label="Sähköposti" 
                          name="email" 
                          variant="outlined" 
                          value={value} 
                          onChange={onChange} 
                          autoFocus 
                          error={!!error} 
                          helperText={error ? error.message : null} 
                        />
                  )}
                />
              </Grid>
              <Grid item xs={12} >
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: 'Syötä salasana'
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField 
                          fullWidth 
                          label="Salasana*"
                          variant="outlined"
                          name="password"
                          value={value}
                          onChange={onChange}
                          type="password"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                  )}
                />
              </Grid>
              <Grid item xs={12}> 
                <Button type="submit" fullWidth variant="contained" color="primary">
                  Poista kohdevahti
                </Button>
              </Grid>
              <Grid item xs>
                <Link href="/haku" variant="body2">
                  Hae kohteita
                </Link>
              </Grid>
              <Grid item>
                <Link href="/kohdevahti" variant="body2">
                  Luo uusi kohdevahti
                </Link>
              </Grid>
            <Grid/>
            </Grid>
            {sentFailure &&
              <Alert severity="error" sx={errorStyle}>
                <AlertTitle>Kohdevahdin poistaminen epäonnistui</AlertTitle>
                {sentFailureMessage}
              </Alert>
            }
          </form>
        }
        {sentSuccess &&
          <Alert  severity="success">
            <AlertTitle>Kohdevahdin poistaminen onnistui</AlertTitle>
            Et enää saa ilmoituksia mahdollisista uusista kohteista.
          </Alert>
        }
      </div>
      }
    </Container>
  );
}