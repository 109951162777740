import React from 'react';
import { Container, Typography, Grid, Avatar }  from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const pictureGridStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

const nameTextStyle = {
  textAlign: 'center',
  fontSize: { xs: '12px', sm: '16px' }
}

const textStyles = {
  fontSize: { xs: '12px', sm: '16px' },
  width:'100%'
}

const iconStyle = {
  fontSize: { xs: '18px', sm: '16px' },
  marginBottom: { xs: '-5px', sm: '-2px' },
  marginRight: '6px'
}

const informationGrid = {
  marginTop: (theme) => theme.spacing(2)
}

export default function OwnerInfo({ omistajatiedot, yritysnimi }) {

  return (
    <Container>
        <Grid container spacing={ {xs: 0, sm: 1, md: 2}}>
          <Grid item container xs={12} sx={pictureGridStyle} spacing={2}>
            <Grid item>
              <Avatar
                alt="Omistajan asettama profiilikuva"
                src={omistajatiedot.profilePicUrl ? omistajatiedot.profilePicUrl : "../contactImage.png"}
                sx={{ '& img': { objectFit: 'contain' }, width: '100%', height: 160 }}
                loading='lazy'
                variant='square'
              />
            </Grid>
            {yritysnimi &&
              <Grid item>
                <Typography variant="body1" sx={nameTextStyle}>{yritysnimi}</Typography>
              </Grid>
            }
          </Grid>
          <Grid item container sx={informationGrid} xs={12} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1" sx={textStyles}><PersonIcon sx={iconStyle}/>{omistajatiedot.firstName} {omistajatiedot.lastName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={textStyles}><MailOutlineIcon sx={iconStyle}/><a href={`mailto:${omistajatiedot.email}`}> {omistajatiedot.email} </a></Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={textStyles}><PhoneIcon sx={iconStyle}/> {omistajatiedot.phoneNum ? <a href={`tel:${omistajatiedot.phoneNum}`}>{omistajatiedot.phoneNum}</a> : "-"}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={textStyles}><LocationOnIcon sx={iconStyle}/> {omistajatiedot.fullAddress} </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={textStyles}>{omistajatiedot.contactInfo ? omistajatiedot.contactInfo : ""}</Typography>
            </Grid>
          </Grid>
        </Grid>
    </Container>
  );
}