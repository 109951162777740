import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from "axios";

import { Container, Grid, Button  } from '@mui/material';


const containerStyle = {
    marginTop: (theme) => theme.spacing(8)
};

const gridContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justiFycontent: 'center'
};

const buttonStyle = {
  width: 320,
  padding: (theme) => theme.spacing(2)
};

export default function Admin() {
  const navigate = useNavigate();

  useEffect(() => {
    verifyAdmin()
  },[]);

  const verifyAdmin = async () => {
    try {
      await axios.get("/api/admin/verifioi")
    } catch(error) {
      navigate('/haku');
    }
  }


  return (
    <Container sx={containerStyle} maxWidth="sm">
      <Grid container sx={gridContainerStyle} spacing={2}>
        <Grid item xs={12}>
          <Button variant="contained" sx={buttonStyle} onClick={() => navigate('/admin/users')}>Käyttäjät</Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" sx={buttonStyle} onClick={() => navigate('/admin/ilmoitukset')}>Ilmoitukset</Button>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" sx={buttonStyle} onClick={() => navigate('/admin/companies')}>Yritykset</Button>
        </Grid>
      </Grid>
    </Container>
  );
}