import React, { useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useParams } from 'react-router-dom';
import axios from "axios";

import {Button, TextField, Grid, Typography, Container, CircularProgress } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

const containerStyle = {
  marginTop: (theme) => theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const circularProgressStyle = {
  margin: (theme) => theme.spacing(4)
}

const errorStyle = {
  marginTop: (theme) => theme.spacing(2),
  padding: "8px 16px",
}

export default function Reset() {
  const [loading, setLoading] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [changeFailure, setChangeFailure] = useState(false);
  const [changeFailureMessage, setChangeFailureMessage] = useState('');
  const { handleSubmit, control, watch } = useForm({ defaultValues: { password: "", confirmPassword: ""}});

  const { token } = useParams()

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await axios.post(`/api/user/vaihda-salasana/${token}`, data)
      setChangeSuccess(true)
      setLoading(false)
    } catch(error) {
      setChangeFailure(true)
      if(error.response.data.message) {
        setChangeFailureMessage(error.response.data.message);
      } else {
        setChangeFailureMessage('Yritä myöhemmin uudelleen tai ole yhteydessä järjestelmänvalvojaan.')
      }
      setLoading(false)
      setTimeout(() => {
        setChangeFailure(false)
        setChangeFailureMessage('')
      }, 5000);
    }
  }

  return (
    <Container sx={containerStyle} maxWidth="xs">
      {loading?
          <CircularProgress color="primary" sx={circularProgressStyle}/>
      :
      <div>
        {!changeSuccess &&
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Typography component="body1" align="center" color="textSecondary">Syötä uusi salasana</Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                    name="password"
                    control={control}
                    rules={{ 
                    required: 'Syötä salasana',
                    minLength: { value: 8, message: "Salasanan oltava vähintään 8 merkkiä" }
                    }}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <TextField fullWidth label="Salasana*" variant="outlined" name={name} value={value} onChange={onChange} type="password" error={!!error} helperText={error ? error.message : null} />
                    )}
                />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="confirmPassword"
                        control={control}
                        rules={{
                        required: 'Vahvista salasana',
                        validate: {isValid: value => value === password.current || "Salasanan vahvistus ei täsmää"}
                        }}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <TextField fullWidth label="Vahvista salasana*" variant="outlined" name={name} value={value} onChange={onChange} type="password" error={!!error} helperText={error ? error.message : null} />
                        )}
                    />
                </Grid>
              <Grid item xs={12}> 
                <Button type="submit" fullWidth variant="contained" color="primary">
                  VAIHDA SALASANA
                </Button>
              </Grid>
            </Grid>
            {changeFailure &&
              <Alert severity="error" sx={errorStyle}>
                <AlertTitle>Salasanan vaihtaminen epäonnistui</AlertTitle>
                {changeFailureMessage}
              </Alert>
            }
          </form>
        }
        {changeSuccess &&
          <Alert  severity="success">
            <AlertTitle>Salasanan vaihtaminen onnistui </AlertTitle>
            Voit nyt kirjautua sisään uudella salasanalla.
          </Alert>
        }
      </div>
      }
    </Container>
  );
}