import React from 'react';
import { Container, List, ListItem, Typography } from '@mui/material';

const containerStyle = {
  marginBottom: (theme) => theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export default function UserTerms() {

  return (
    <Container maxWidth="lg" sx={containerStyle}>
        <List>
            <ListItem>
              <Typography>
                <b>1. Myynti-ilmoituksen sisältö: </b>Myynti-ilmoituksen tekijä on täysin vastuussa ilmoituksen sisällöstä, 
                kuten teksteistä kuvista ja tiedoista. Myyjä takaa, että ilmoituksessa esitetyt tiedot ovat tarkkoja ja oikeita.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <b>2. Palvelulmoituksen sisältö: </b>Palvelun ilmoittaja on täysin vastuussa ilmoituksen sisällöstä, kuten teksteistä kuvista ja tiedoista. 
                Ilmoittaja takaa, että ilmoituksessa esitetyt tiedot ovat tarkkoja ja oikeita.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <b>3. Käyttäjän vastuu: </b>Käyttäjä ymmärtää, että Nettimetsä.fi ei ota vastuuta myynti- tai palveluilmoitusten sisällöistä, tarkkuudesta tai luotettavuudesta. 
                Ilmoitusten jättäjä on yksin vastuussa ilmoituksistaan ja niiden vaikutuksista.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <b>4. Vastuun rajoitus: </b>Palveluntarjoaja ei ole vastuussa suorista tai välillisistä vahingoista, jotka johtuvat palvelun käytöstä tai sen saatavuudesta.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <b>5. Yksityisyys: </b>Käyttäjän antamat tiedot käsitellään yksityisyyskäytännön mukaisesti. Käyttäjä hyväksyy tietojen käsittelyn ja tallentamisen.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <b>6. Käyttäjien poistaminen: </b>Palveluntarjoajalla on oikeus poistaa käyttäjä palvelusta, mikäli käyttäjä rikkoo palvelun käyttöehtoja, 
                aiheuttaa häiriöitä tai väärinkäyttää palvelua.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <b>7. Palvelun muutokset: </b>Palveluntarjoajalla on oikeus tehdä muutoksia palveluun, 
                mukaan lukien palvelun ominaisuudet, toiminnot ja käyttöehdot, ilmoittamalla siitä palvelussa.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <b>8. Uutiskirjeen tilaaminen: </b>Myynti-ilmoituksen tekijä suostuu tilaamaan palvelun uutiskirjeen. 
                Uutiskirjeen voi peruuttaa milloin tahansa uutiskirjeen sähköpostin palveluntarjoajan kautta tai laittamalla peruutuspyynnön Nettimetsän ylläpidolle.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <b>9. Sopimusehtojen muutokset: </b>Palveluntarjoajalla on oikeus muuttaa näitä käyttöehtoja ilmoittamalla siitä palvelussa.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <b>10. XML tuonnin käyttö: </b>Jos käytetään XML-tuontia, ilmoituksen tekijä vastaa siitä, että tuotavat arvot ovat oikein ja paikkansapitäviä.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <b>11. Myynti- ja palveluilmoituksien markkinointi: </b>
                Nettimetsä.fi:llä on oikeus markkinoida palvelun myynnissä olevia tiloja ja palveluilmoituksia sekä käyttää myynti-ilmoituksessa esitettyjä kuvia markkinointiin. 
                Markkinointia suoritetaan Instagramissa, Facebookissa, TikTokissa ja uutiskirjeen välityksellä. Kuvia ja tietoja käytetään ainoastaan kohteen markkinointiin. 
                Markkinoinnin pääviestinä on kohteen kokonaisvaltaisen näkyvyyden kasvattaminen, ja markkinointi suoritetaan hyvän välitystavan mukaisesti. 
                Voit erikseen kieltää kohteiden markkinoinnin laittamalla markkinoinnin peruutuspyynnön Nettimetsän ylläpidolle.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <b>12. Metsän myyjän opas: </b>
                Lataamalla oppaan, lataaja suostuu tilaamaan Nettimetsän uutiskirjeen ja hyväksyy seuraavat ehdot: <br/>
                Oppaan lataaja hyväksyy, että opas on tarkoitettu ainoastaan informatiiviseksi resurssiksi henkilöille, jotka harkitsevat metsätilan myymistä. 
                Opas on tuotettu yleisen tiedon jakamiseen liittyvässä tarkoituksessa. 
                Oppaan laatija tai jakelija ei ota vastuuta oppaan sisällön käytöstä mahdollisesti aiheutuvista taloudellisista menetyksistä tai muista seurauksista. 
                Myyntipäätökset ovat aina henkilökohtaisia ja niihin liittyy riskejä, jotka tulee huomioida yksilöllisesti.
              </Typography>
            </ListItem>
        </List>
    </Container>
  );
}