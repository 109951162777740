import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import 'dayjs/locale/fi';

const customTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#286d2e',
      extralight: '#C9E0BE'
    },
  },
  typography: {
    fontFamily: [
      'Garet',
      'sans-serif',
    ].join(','),
  },
  components: {
    // Example: Customize the Button component
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 40,
          border: '0px',
        },
      },
    },
  },
});

const datePickerStyle = {
  width: (theme) => theme.spacing(28),
};

export default function BasicDatePicker({onChange, value, pickDisabled, name}) {
  return (
    <ThemeProvider theme={customTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi" localeText={{datePickerToolbarTitle: 'Valitse päivä', cancelButtonLabel: 'Peruuta', clearButtonLabel: 'Tyhjennä' }}>
          <DatePicker sx={datePickerStyle} name={name} value={value} onChange={onChange} disabled={pickDisabled}/>
      </LocalizationProvider>
    </ThemeProvider>
  );
}