import React, { useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

import { Alert, AlertTitle } from '@mui/material';

import {
  Button, TextField, FormControlLabel, Checkbox, Link, Grid, Typography, Container, Backdrop, CircularProgress
} from '@mui/material';

const rootContainerStyle = {
  marginTop: (theme) => theme.spacing(8),
  marginBottom: (theme) => theme.spacing(12),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const innerContainerStyle = {
  paddingTop: (theme) => theme.spacing(4),
  paddingBottom: (theme) => theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: '5px',
  borderColor: (theme) => theme.palette.primary.light,
  borderRadius: '25px',
};

const linkStyle = {
  textAlign: 'center',
}

export default function SignUp() {
  const { handleSubmit, control, watch, reset } = useForm({ defaultValues: {
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    address: "",
    phoneNum: "",
    contactInfo: "",
    password: "",
    confirmPassword: ""
  }});

  const password = useRef({});
  password.current = watch("password", "");

  const [loading, setLoading] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registrationSuccessMessage, setRegistrationSuccessMessage] = useState('');
  const [registrationFailure, setRegistrationFailure] = useState(false);
  const [registrationFailureMessage, setRegistrationFailureMessage] = useState('');

  const [userTermsAgreed, setUserTermsAgreed] = useState(false);


  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const response = await axios.post("/api/user/rekisteroityminen", data)
      setRegistrationSuccess(true);
      setRegistrationSuccessMessage(response.data.message)
      setUserTermsAgreed(false)
      reset();
      setLoading(false)
    } catch(error) {

      setRegistrationFailure(true)
      setRegistrationFailureMessage(error.response.data.message)
      setLoading(false)
      setTimeout(() => {
        setRegistrationFailure(false)
        setRegistrationFailureMessage('')
      }, 5000);
    }
  }

  return (
    <Container maxWidth="sm" sx={rootContainerStyle}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!registrationSuccess &&
        <Container sx={innerContainerStyle}>
          <Typography variant="h4" component="h1" gutterBottom>Rekisteröidy</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: 'Syötä etunimi',
                  maxLength: { value: 50, message: "Etunimen oltava korkeintaan 50 merkkiä" },
                }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField fullWidth label="Etunimi*" name={name} variant="outlined" value={value} onChange={onChange} autoFocus error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: 'Syötä sukunimi',
                  maxLength: { value: 50, message: "Sukunimen oltava korkeintaan 50 merkkiä" },
                }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField fullWidth label="Sukunimi*" name={name} variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Syötä sähköposti',
                  maxLength: { value: 50, message: "Sähköpostin oltava korkeintaan 50 merkkiä" },
                  pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: "Tarkista sähköposti" }}}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField fullWidth label="Sähköposti*" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <Controller
                name="phoneNum"
                control={control}
                rules={{ maxLength: { value: 15, message: "Puhelinnumeron oltava korkeintaan 15 merkkiä" } }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField fullWidth label="Puhelinnumero" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="city"
                control={control}
                rules={{ maxLength: { value: 50, message: "Kaupungin nimen oltava korkeintaan 50 merkkiä" } }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField fullWidth label="Kaupunki" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="address"
                control={control}
                rules={{ maxLength: { value: 50, message: "Osoitteen oltava korkeintaan 50 merkkiä" } }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField fullWidth label="Osoite" variant="outlined" name={name} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null}/>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="contactInfo"
                control={control}
                rules={{ maxLength: { value: 400, message: "Vapaan tekstikentän oltava korkeintaan 400 merkkiä" } }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField multiline hintText="test"  rows={3} fullWidth label="Vapaa tekstikenttä" placeholder="Vapaa tekstikenttä. Kerro esim paras aika ja tapa ottaa yhteyttä tai mainosta yritystäsi!" name={name} variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null}/>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="password"
                control={control}
                rules={{ 
                  required: 'Syötä salasana',
                  minLength: { value: 8, message: "Salasanan oltava vähintään 8 merkkiä" }
                }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField fullWidth label="Salasana*" variant="outlined" name={name} value={value} onChange={onChange} type="password" error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
             <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: 'Vahvista salasana',
                  validate: {isValid: value => value === password.current || "Salasanan vahvistus ei täsmää"}
                }}
                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                  <TextField fullWidth label="Vahvista salasana*" variant="outlined" name={name} value={value} onChange={onChange} type="password" error={!!error} helperText={error ? error.message : null} />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={linkStyle}>
              <FormControlLabel
                control={ <Checkbox value={userTermsAgreed} color="primary" name="termsAccepted" onChange={e => setUserTermsAgreed(e.target.checked)} />}
                label={ <Typography variant="body2"> Olen lukenut palvelun <a href='/kayttoehdot' target="_blank" rel="noreferrer">käyttöehdot</a> ja hyväksyn ne.</Typography> } 
              />
            </Grid>
            <Grid item xs={12}>
              <Button disabled={!userTermsAgreed} type="submit" fullWidth variant="contained" color="primary" >
                REKISTERÖIDY
              </Button>
            </Grid>
            <Grid item xs={12} sx={linkStyle}>
              <Link href="/kirjautuminen" variant="body2">
                Onko sinulla jo käyttäjätili? Kirjaudu sisään.
              </Link>
            </Grid>
          </Grid>
          {registrationFailure &&
            <Alert severity="error">
              <AlertTitle>Rekisteröityminen epäonnistui</AlertTitle>
              {registrationFailureMessage}
            </Alert>
          }
        </Container>
        }
      </form>
      {registrationSuccess &&
        <Alert severity="success">
          <AlertTitle>Rekisteröityminen onnistui</AlertTitle>
          {registrationSuccessMessage}
        </Alert>
      }
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}