import React from 'react';
import { Controller } from "react-hook-form";
import { NumericFormat } from 'react-number-format';

import { Grid, List, ListSubheader, ListItem, TextField, MenuItem } from '@mui/material';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const headerStyle = {
  fontSize: '16px',
}

const listItemStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const fieldStyle = {
  width: '100%',
  maxWidth: (theme) => theme.spacing(48),
};

const innerContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

export default function Yleisetpuustotiedot({control}) {

  return (
      <div >
        <Grid sx={containerStyle} item container spacing={2} xs={12}>
          <Grid item xs={12} sx={innerContainerStyle}>
              <List subheader={<ListSubheader sx={headerStyle}>Yleiset puustotiedot</ListSubheader>} sx={{width: '100%'}}>
                <ListItem sx={listItemStyle}>
                  <Controller
                    name="puustonlaatu"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <TextField
                        sx={fieldStyle}
                        select
                        variant="standard"
                        label="Puuston yleinen laatu (asteikolla 1-5)"
                        InputLabelProps={{shrink: true}}
                        value={value ? value : ""}
                        name={name}
                        onChange={onChange}
                      >
                        <MenuItem key={0} value={""}>Tyhjä</MenuItem>
                        <MenuItem key={1} value={"1. Heikko"}>1. Heikko</MenuItem>
                        <MenuItem key={2} value={"2. Välttävä"}>2. Välttävä</MenuItem>
                        <MenuItem key={3} value={"3. Tyydyttävä"}>3. Tyydyttävä</MenuItem>
                        <MenuItem key={4} value={"4. Hyvä"}>4. Hyvä</MenuItem>
                        <MenuItem key={5} value={"5. Erinomainen"}>5. Erinomainen</MenuItem>
                      </TextField>
                    )}
                  />
                </ListItem>
                <ListItem sx={listItemStyle}>
                  <Controller
                    name="metsanhoidollinentila"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <TextField
                        sx={fieldStyle}
                        select
                        variant="standard"
                        label="Metsänhoidollinen tila (asteikolla 1-5)"
                        InputLabelProps={{shrink: true}}
                        value={value ? value : ""}
                        name={name}
                        onChange={onChange}
                      >
                        <MenuItem key={0} value={""}>Tyhjä</MenuItem>
                        <MenuItem key={1} value={"1. Heikko"}>1. Heikko</MenuItem>
                        <MenuItem key={2} value={"2. Välttävä"}>2. Välttävä</MenuItem>
                        <MenuItem key={3} value={"3. Tyydyttävä"}>3. Tyydyttävä</MenuItem>
                        <MenuItem key={4} value={"4. Hyvä"}>4. Hyvä</MenuItem>
                        <MenuItem key={5} value={"5. Erinomainen"}>5. Erinomainen</MenuItem>
                      </TextField>
                    )}
                  />
                </ListItem>
                <ListItem sx={listItemStyle}>
                  <Controller
                    name="korjuuolosuhteet"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <TextField
                        sx={fieldStyle}
                        select
                        variant="standard"
                        label="Metsätilan korjuuolosuhteet (asteikolla 1-5)"
                        InputLabelProps={{shrink: true}}
                        value={value ? value : ""}
                        name={name}
                        onChange={onChange}
                      >
                        <MenuItem key={0} value={""}>Tyhjä</MenuItem>
                        <MenuItem key={1} value={"1. Heikko"}>1. Heikko</MenuItem>
                        <MenuItem key={2} value={"2. Välttävä"}>2. Välttävä</MenuItem>
                        <MenuItem key={3} value={"3. Tyydyttävä"}>3. Tyydyttävä</MenuItem>
                        <MenuItem key={4} value={"4. Hyvä"}>4. Hyvä</MenuItem>
                        <MenuItem key={5} value={"5. Erinomainen"}>5. Erinomainen</MenuItem>
                      </TextField>
                    )}
                  />
                </ListItem>
                <ListItem sx={listItemStyle}>
                  <Controller
                    name="hakkuumahdollisuudet"
                    control={control}
                    render={({ field: { onChange, value, name } }) => (
                      <NumericFormat
                        sx={fieldStyle}
                        isNumericString={true}
                        decimalScale={0}
                        allowNegative={false}
                        allowLeadingZeros={false}
                        thousandSeparator=" "
                        isAllowed={(values) => {return values.value < 100000}}
                        label="Heti olevat hakkuumahdollisuudet (m³)"
                        variant="standard"
                        InputLabelProps={{shrink: true}}
                        value={value}
                        name={name}
                        onValueChange={values => {
                          onChange({
                            target: {
                              value: values.value
                            }
                          });
                        }}
                        customInput={TextField}
                      />
                    )}
                  />
                </ListItem>
              </List>
          </Grid>
        </Grid>
      </div>
  );
}