import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function PuustotiedotBarChart({dataset}) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const renderCustomizedLabel = (props) => {
    const {
      x, y, width, height, value, isMobile
    } = props;
  
    if(isMobile === true) {
      return ""
    }

    return (
        <text x={x + (width / 2)} y={y - 5} style={{ fill: "black", fontFamily: 'Garet', fontSize:'14px' }} textAnchor="middle">
          {value}
        </text>
    );
  };

  const CustomTooltip = ({ payload }) => {
    if (payload && payload.length) {
        // Extract the data you need from the payload
        const data = payload[0].payload;
        // Customize the tooltip content
        return <div style={{backgroundColor: "white", padding: "4px"}}><Typography>{data.fullname}: {data.value} m³</Typography></div>;
    }
    return null;
  };
  

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={500}
        data={dataset}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" style={{fontFamily: 'Garet', fontSize: '14px'}}/>
        <YAxis style={{fontFamily: 'Garet'}} domain={[0, dataMax => (Math.round(dataMax * 1.1))]}/>
        <Bar
          isAnimationActive={false}
          dataKey="value"
          fill="#8884d8"
          >
          {dataset.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
        <LabelList dataKey="label" content={(props) => renderCustomizedLabel({ ...props, isMobile: isMobile })} style={{ fill: "black", fontFamily: 'Garet' }}/>
        </Bar>
        <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip />} active={isMobile}/>
      </BarChart>
    </ResponsiveContainer>
  );
}