const formatNumberWithSeparator = (number) => {
    // Format the number with a custom regular expression to have spaces as thousands separator
    if(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return number;
  };

  const checkIfAnyPuustotieto = (ilmoitustiedot) => {

    const propertiesToCheck = [
      "aukea_koko",
      "siemenpuumetsikko_koko",
      "taimikkoalle13_koko",
      "taimikkoyli13_koko",
      "ylispuustoinentaimikko_koko",
      "nuorikasvatusmetsikko_koko",
      "varttunutkasvatusmetsikko_koko",
      "uudistuskypsametsikko_koko",
      "suojuspuumetsikko_koko",
      "vajaatuottoinenmetsikko_koko",
      "eriikaisrakenteinenmetsikko_koko",
      "eitietoa_koko",
      "lehto_koko",
      "lehtomainenkangas_koko",
      "tuorekangas_koko",
      "kuivahkokangas_koko",
      "kuivakangas_koko",
      "karukkokangas_koko",
      "kitumaa_koko",
      "joutomaa_koko",
      "mantytukki_maara",
      "kuusitukki_maara",
      "koivutukki_maara",
      "mantykuitu_maara",
      "kuusikuitu_maara",
      "koivukuitu_maara",
      "muukuitu_maara",
      "muutukki_maara",
      "puustonlaatu",
      "metsanhoidollinentila",
      "korjuuolosuhteet",
      "hakkuumahdollisuudet"
    ];

    const areAllEmptyOrNull = propertiesToCheck.every(prop => ilmoitustiedot[prop] === null || ilmoitustiedot[prop] === '');

    return !areAllEmptyOrNull;
  };

  module.exports = {
    formatNumberWithSeparator,
    checkIfAnyPuustotieto
  }