const ilmoitusDefaultValues = {
  tyyppi: "Metsätila (kiinteistö)",
  kiinteistonimi: "",
  koko: "",
  metsamaa_koko: "",
  muumaa_koko: "",
  hintapyynto: "",
  tarjouskauppa: "false",
  tarjouskauppa_paattyy: "",
  maakunta: "",
  kunta: "",
  kiinteistotunnus: "",
  puu_maara: "",
  mantytukki_maara: "",
  kuusitukki_maara: "",
  koivutukki_maara: "",
  mantykuitu_maara: "",
  kuusikuitu_maara: "",
  koivukuitu_maara: "",
  muukuitu_maara: "",
  muutukki_maara: "",
  metsa_arvio: "Ei ole",
  metsa_arvio_ajankohta: "",
  yritysnimi: "",
  korjuuolosuhteet: "",
  hakkuumahdollisuudet: "",
  metsanhoidollinentila: "",
  puustonlaatu: "",
  rajoitteet: "Ei ole",
  kiinnitykset_ja_rasitukset: "Ei ole",
  tieoikeudet: "Ei ole",
  osuus_yhteisiin: "Ei ole",
  rakennusoikeus: "Ei ole",
  kaava: "Ei ole",
  lisatietoja: "",
  kotisivulinkki: "",
  videolinkki: "",
  tarjousohjeet: "",
  aukea_koko: "",
  siemenpuumetsikko_koko: "",
  taimikkoalle13_koko: "",
  taimikkoyli13_koko: "",
  ylispuustoinentaimikko_koko: "",
  nuorikasvatusmetsikko_koko: "",
  varttunutkasvatusmetsikko_koko: "",
  uudistuskypsametsikko_koko: "",
  suojuspuumetsikko_koko: "",
  vajaatuottoinenmetsikko_koko: "",
  eriikaisrakenteinenmetsikko_koko: "",
  eitietoa_koko: "",
  lehto_koko: "",
  lehtomainenkangas_koko: "",
  tuorekangas_koko: "",
  kuivahkokangas_koko: "",
  kuivakangas_koko: "",
  karukkokangas_koko: "",
  kitumaa_koko: "",
  joutomaa_koko: "",
  yhteismetsa_nimi: "",
  yhteismetsa_kotisivut: "",
  yhteismetsa_osuudet: "",
  yhteismetsa_kokonaisosuudet: "",
  yhteismetsa_koko: "",
  kuvat: [],
  liitteet: []
};

export default ilmoitusDefaultValues;