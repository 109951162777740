import React from 'react';
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  ButtonBase,
  useMediaQuery
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import InputIcon from '@mui/icons-material/Input';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InfoIcon from '@mui/icons-material/Info';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import HailIcon from '@mui/icons-material/Hail';

import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import DrawerComponent from './Drawer';
import { Hail } from '@mui/icons-material';

const containerStyle = {
  width: '100%',
  height: '100px',
  display: 'flex',
  alignItems: 'center'
}

const homePageButtonStyle = {
  fontSize: {xs: '24px', md: '32px'},
  fontFamily: 'Garet',
  fontWeight: '900',
}

function Navigationbar({isLoggedIn, logOut}) {

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  
  const handleHomePageClick = () => {
    navigate('/home');
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <ButtonBase sx={homePageButtonStyle} onClick={handleHomePageClick}>
          Nettimetsä
        </ButtonBase>
          { isMobile ?
            <DrawerComponent isLoggedIn={isLoggedIn} logOut={logOut} />
            :
          isLoggedIn ?
          <Tabs sx={containerStyle} variant="fullWidth" indicatorColor="secondary" textColor="white" value={false}>
            <Tab icon={<SearchIcon />} label="Hae kohteita" value={0} component={Link} to="/haku" />
            <Tab icon={<PersonSearchIcon />} label="Etsi palvelua" value={3} component={Link} to="/palvelut" />
            <Tab icon={<AssignmentIcon />} label="Omat ilmoitukset" value={1} component={Link} to="/ilmoitukset" />
            <Tab icon={<AccountCircleIcon />} label="Oma profiili" value={2} component={Link} to="/profiili" />
            <Tab icon={<AddAlertIcon />} label="Luo kohdevahti" value={8} component={Link} to="/kohdevahti" />
            <Tab icon={<InfoIcon />} label="Yhteystiedot" value={4} component={Link} to="/info" />
            <Tab icon={<ExitToAppIcon />} label="Kirjaudu ulos" value={5} onClick={logOut} />
          </Tabs>
          :
          <Tabs sx={containerStyle} variant="fullWidth" indicatorColor="secondary" textColor="white" value={false} >
            <Tab icon={<SearchIcon />} label="Hae kohteita" value={0} component={Link} to="/haku" />
            <Tab icon={<PersonSearchIcon />} label="Etsi palvelua" value={3} component={Link} to="/palvelut" />
            <Tab icon={<AddAlertIcon />} label="Luo kohdevahti" value={8} component={Link} to="/kohdevahti" />
            <Tab icon={<InfoIcon />} label="Yhteystiedot" value={4} component={Link} to="/info" />
            <Tab icon={<InputIcon />} label="Kirjaudu sisään" value={6} component={Link} to="/kirjautuminen" />
            <Tab icon={<HowToRegIcon />} label="Rekisteröidy" value={7} component={Link} to="/rekisteroityminen" />
          </Tabs>
        }
      </Toolbar>
    </AppBar>
  );
}
export default Navigationbar;