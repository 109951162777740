import React from 'react';
import {
  Paper,
  Typography,
  useMediaQuery
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { Link } from 'react-router-dom';

const footerStyle = {
  marginTop: (theme) => theme.spacing(4),
  paddingBotton: (theme) => theme.spacing(8),
  backgroundColor: (theme) => theme.palette.primary.main,
  width:'100%',
  minHeight: (theme) => theme.spacing(8),
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '20px',
  bottom: 0,
  position: 'absolute'
}

const linkStyle = {
  color: "white",
  textDecoration: "none",
  fontSize: {xs:"12px", sm:"16px"},
}

function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Paper sx={footerStyle} color="primary" component="footer" square variant="elevation">
      <Typography component={Link} to="/home" sx={linkStyle} display={isMobile ? 'none' : 'block'}>
        Nettimetsä.fi
      </Typography>
      <Typography component={Link} to="/haku" sx={linkStyle}>
        Hae kohteita
      </Typography>
      <Typography component={Link} to="/palvelut" sx={linkStyle}>
        Etsi palvelua
      </Typography>
      <Typography component={Link} to="/info" sx={linkStyle}>
        Yhteystiedot
      </Typography>
      <Typography component={Link} to="/mediamyynti" sx={linkStyle}>
        Mediamyynti
      </Typography>
      <Typography component={Link} to="/ohjeet" sx={linkStyle}>
        Ohjeet
      </Typography>
      <Typography component={Link} to="/kayttoehdot" sx={linkStyle}>
        Käyttöehdot
      </Typography>
    </Paper>
  );
}
export default Footer;