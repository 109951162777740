import React, {useEffect, useState} from 'react';
import axios from "axios";

import {Container, Grid, List, ListItem, Typography, Button, Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, AlertTitle } from '@mui/material';

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    marginTop: (theme) => theme.spacing(2),
    marginBottom: (theme) => theme.spacing(8)
}

const buttonContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}

const buttonStyle = {
    width: '100%'
}

export default function IlmoitusList() {

  const [ilmoitukset, setIlmoitukset] = useState([])
  const [loading, setLoading] = useState(true);
  const [removeFailure, setRemoveFailure] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [removingIlmoitusID, setRemovingIlmoitusID] = useState("");

  const handleInitialRemoveTry = (ilmoitus_id) => {
    setRemovingIlmoitusID(ilmoitus_id)
    setOpenConfirmDialog(true)
  }

  const handleRemove = async () => {
    setLoading(true)
    setOpenConfirmDialog(false)
    try {
      await axios.delete("/api/admin/poista-ilmoitus/" + removingIlmoitusID);
      getIlmoitukset();
    } catch(error) {
        setRemoveFailure(true)
        setLoading(false)
        setTimeout(() => {
            setRemoveFailure(false)
        }, 5000);
    }
  }

  useEffect(() => {
    getIlmoitukset();
  }, [])

  const getIlmoitukset = async () => {
    try {
      const response = await axios.get("/api/admin/ilmoitukset");
      setIlmoitukset(response.data);
      setLoading(false)
    } catch(error) {
      console.log(error);
      setLoading(false)
    }
  }

  const closeConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <Container sx={containerStyle}>
        {removeFailure &&
            <Alert severity="error">
                <AlertTitle>Ilmoitukset poistaminen epäonnistui</AlertTitle>
            </Alert>
        }
      <List name="ilomoituslist">
        {ilmoitukset?.map((ilmoitus) => {
          return (
            <ListItem key={ilmoitus.ilmoitus_id} >
                <Grid container item spacing={1} xs={12}>
                    <Grid item container xs={9}  >
                        <Grid item xs={12} sm={6} md={6} >
                            <Typography variant="body2"><b>ID:</b> {ilmoitus.ilmoitus_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} >
                            <Typography variant="body2"><b>Näyttökerrat:</b> {ilmoitus.view_count}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography variant="body2"><b>Kiinteistötunnus:</b> {ilmoitus.kiinteistotunnus}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography variant="body2"><b>Email:</b> {ilmoitus.user_email}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} sx={buttonContainerStyle}>
                        <Button sx={buttonStyle} variant="contained" color="secondary" onClick={() => {handleInitialRemoveTry(ilmoitus.ilmoitus_id)}} >
                        Poista
                        </Button>
                    </Grid>
                </Grid>
            </ListItem>
          )
        })}
        <ListItem key="overall" >
          <Typography variant="body2"><b>Näyttökerrat yhteensä:</b> {ilmoitukset.reduce((acc, ilmoitus) => acc + ilmoitus.view_count, 0)}</Typography>
        </ListItem>
      </List>
      <Dialog open={openConfirmDialog} onClose={closeConfirmDialog} >
        <DialogTitle>
          Haluatko varmasti poistaa ilmoitukset?
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleRemove}>Poista ilmoitus</Button>
          <Button variant="contained" onClick={closeConfirmDialog} autoFocus>Peruuta</Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}