import axios from "axios";

import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

import Admin from './pages/adminportal/Admin';
import UsersList from "./pages/adminportal/UserList";
import IlmoitusList from "./pages/adminportal/IlmoitusList";
import CompanyList from './pages/adminportal/CompanyList';
import CompanyWrapper from './pages/adminportal/CompanyWrapper';

import Navigationbar from './NavigationBar';
import Footer from './Footer';

import Home from './pages/home/Home';
import Instructions from './pages/instructions/Instructions';
import Mediamyynti from './pages/mediamyynti/Mediamyynti';
import UserTerms from './pages/terms/UserTerms';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';
import ContactInfo from './pages/contact/ContactInfo';
import ServiceSearch from './pages/services/ServiceSearch';
import ProfileWrapper from './pages/profile/Profile-wrapper';
import Kohdevahti from './pages/kohdevahti/Kohdevahti';
import KohdevahtiDelete from "./pages/kohdevahti/KohdevahtiDelete";

import SendReset from './pages/passwordreset/SendReset';
import Reset from './pages/passwordreset/Reset';
import ItemSearch from './pages/search/ItemSearch';
import Ilmoitus from './pages/ilmoitus/Ilmoitus';

import RegistrationSuccess from './pages/registrationverification/RegistrationSuccess';
import RegistrationFailure from './pages/registrationverification/RegistrationFailure';

import IlmoituksetWrapper from './pages/user-ilmoitukset/Ilmoitukset-wrapper';

import MyyntiIlmoitusWrapper from './pages/myynti-ilmoitus/Myynti-ilmoitus-wrapper'

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container } from '@mui/system';

let theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#286d2e',
      extralight: '#C9E0BE'
    },
    secondary: {
      main: '#22645f',
    }
  },
  typography: {
    fontFamily: [
      'Garet',
      'sans-serif',
    ].join(','),
  },
  components: {
    // Name of the component
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: "13px",
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        // Name of the slot
        root: ({ theme }) => ({
          // CSS
          wordWrap: 'break-word',
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: ({ theme }) => ({
          // CSS
          borderRadius: 40,
          backgroundColor: theme.palette.primary.light,
          padding: (theme) => theme.spacing(1),
          variant: 'outlined',
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 20,
        }),
        standardSuccess: ({ theme }) => ( {
          color: theme.palette.primary.main,
          backgroundColor: "white",
          padding: "16px 32px",
          border: '2px solid',
          borderColor: theme.palette.primary.main,
        }),
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) => ({
          // This puts the backdrop on top of the all the elements
          zIndex: theme.zIndex.drawer + 1
        }),
      },
    },
    MuiInput: {
      // This removes the arrows from number type Textfield components
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
  },
});

const containerStyle = {
  backgroundColor: "white",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflowX: 'hidden',
  minHeight: '100vh',
  position: 'relative'
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    checkLogged()
  }, []);

  const checkLogged = async () => {
    try {
      const response = await axios.get("/api/user/on-kirjautunut")
      if (response.data.logged) {
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false)
      }
    } catch(error) {
      console.log(error)
    }
  }

  const logOut = async () => {
    try {
      // Navigating to the front page after successfull logout.
      await axios.get("/api/user/kirjaudu-ulos")
      window.location.assign('/haku');
      setIsLoggedIn(false)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
          <Container sx={containerStyle} maxWidth={false} disableGutters>
            <Navigationbar isLoggedIn={isLoggedIn} logOut={logOut}/>
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/haku" element={<ItemSearch />} />
              <Route path="/haku/:id" element={<Ilmoitus />} />
              <Route path="/kirjautuminen" element={<Login setIsLoggedIn={setIsLoggedIn}/>} />
              <Route path="/rekisteroityminen" element={<Signup />} />
              <Route path="/salasana-resetointi" element={<SendReset />} />
              <Route path="/salasana-resetointi/:token" element={<Reset />} />
              <Route path="/rekisterointi-onnistui" element={<RegistrationSuccess />} />
              <Route path="/rekisterointi-epaonnistui" element={<RegistrationFailure />} />
              <Route path="/profiili" element={<ProfileWrapper setIsLoggedIn={setIsLoggedIn} />} />
              <Route path="/ilmoitukset" element={<IlmoituksetWrapper isLoggedIn={isLoggedIn}/>} />
              <Route path="/ilmoitukset/:id" element={<MyyntiIlmoitusWrapper isLoggedIn={isLoggedIn}/>} />
              <Route path="/kohdevahti" element={<Kohdevahti />} />
              <Route path="/kohdevahti-poista" element={<KohdevahtiDelete />} />
              <Route path="/palvelut" element={<ServiceSearch />} />
              <Route path="/info" element={<ContactInfo />} />
              <Route path="/ohjeet" element={<Instructions />} />
              <Route path="/kayttoehdot" element={<UserTerms />} />
              <Route path="/mediamyynti" element={<Mediamyynti />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/admin/users" element={<UsersList />} />
              <Route path="/admin/ilmoitukset" element={<IlmoitusList />} />
              <Route path="/admin/companies" element={<CompanyList />} />
              <Route path="/admin/companies/:id" element={<CompanyWrapper />} />
              <Route path="*" element={<Navigate to="/haku" />}/>
            </Routes>
            <Footer />
          </Container>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
