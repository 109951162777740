import React from 'react';
import { NumericFormat } from 'react-number-format';

import { Controller } from "react-hook-form";
import {Grid, TextField, Container  } from '@mui/material';

const containerStyle = {
  width: {xs: '100%', sm: "70%"}
}

export default function Yhteismetsatiedot({ control, ilmoitustyyppiSelect }) {

  return (
    <Container sx={containerStyle}>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={6}>
          <Controller
            name="yhteismetsa_nimi"
            control={control}
            rules={{
              maxLength: { value: 100, message: "Nimen oltava korkeintaan 100 merkkiä" },
              validate: value => !(ilmoitustyyppiSelect.current === 'Yhteismetsäosuus' && !value) || "Syötä yhteismetsän nimi"
            }}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <TextField
                // Here adding the indication if the field is required
                label={ilmoitustyyppiSelect.current === 'Yhteismetsäosuus' ? "Yhteismetsän nimi*" : "Yhteismetsän nimi"}
                variant="standard"
                fullWidth
                value={value}
                name={name}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="yhteismetsa_koko"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <NumericFormat
                isNumericString={true}
                decimalScale={2}
                allowNegative={false}
                allowLeadingZeros={false}
                thousandSeparator=" "
                decimalSeparator="."
                isAllowed={(values) => {return values.value < 1000000}} // Yhteistilan koon tulisi olla alle miljoona hehtaaria
                label="Yhteismetsän koko (ha)"
                variant="standard"
                fullWidth
                value={value}
                name={name}
                onValueChange={values => {
                  onChange({
                    target: {
                      value: values.value
                    }
                  });
                }}
                customInput={TextField}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="yhteismetsa_osuudet"
            control={control}
            rules={{
              validate: value => !(ilmoitustyyppiSelect.current === 'Yhteismetsäosuus' && !value) || "Syötä osuudet"
            }}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <NumericFormat
                isNumericString={true}
                decimalScale={6}
                allowNegative={false}
                allowLeadingZeros={false}
                thousandSeparator=" "
                decimalSeparator="."
                isAllowed={(values) => {return values.value < 1000000}} // Yhteismetstilan osuuksia tulisi olla alle miljoona
                // Here adding the indication if the field is required
                label={ilmoitustyyppiSelect.current === "Yhteismetsäosuus" ? "Myynnissä olevat osuudet*" : "Myynnissä olevat osuudet"}
                variant="standard"
                fullWidth
                error={!!error}
                helperText={error ? error.message : null}
                value={value}
                name={name}
                onValueChange={values => {
                  onChange({
                    target: {
                      value: values.value
                    }
                  });
                }}
                customInput={TextField}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="yhteismetsa_kokonaisosuudet"
            control={control}
            rules={{
              validate: value => !(ilmoitustyyppiSelect.current === 'Yhteismetsäosuus' && !value) || "Syötä kokonaisosuudet"
            }}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <NumericFormat
                isNumericString={true}
                decimalScale={6}
                allowNegative={false}
                allowLeadingZeros={false}
                thousandSeparator=" "
                decimalSeparator="."
                isAllowed={(values) => {return values.value < 1000000}} // Yhteismetstilan osuuksia tulisi olla alle miljoona
                // Here adding the indication if the field is required
                label={ilmoitustyyppiSelect.current === "Yhteismetsäosuus" ? "Kokonaisosuudet*" : "Kokonaisosuudet"}
                variant="standard"
                fullWidth
                error={!!error}
                helperText={error ? error.message : null}
                value={value}
                name={name}
                onValueChange={values => {
                  onChange({
                    target: {
                      value: values.value
                    }
                  });
                }}
                customInput={TextField}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="yhteismetsa_kotisivut"
            control={control}
            rules={{
              maxLength: { value: 100, message: "Kotisivujen nimen oltava korkeintaan 100 merkkiä" },
            }}
            render={({ field: { onChange, value, name }, fieldState: { error }  }) => (
              <TextField
                label="Yhteismetsän kotisivut"
                variant="standard"
                fullWidth
                value={value}
                name={name}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
      </Grid>
    </Container>
  );
}