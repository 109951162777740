import React, { useEffect, useState } from 'react';
import { Controller } from "react-hook-form";

import {ImageList, Button, ImageListItem, ImageListItemBar, IconButton, Container, Alert, AlertTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const imageListStyles = {
  overflowX: 'auto', // allow horizontal scrolling
  display: 'flex',
  flexDirection: 'row',
  rowHeight: 164,
  width: {xs: 300, sm: 600, md: 800 },
}

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const buttonStyle = {
  margin: (theme) => theme.spacing(2, 2),
  width: (theme) => theme.spacing(24)
}

const alertStyle = {
  marginBottom: (theme) => theme.spacing(2)
}

export default function Kuvat({control, getValues, setValue}) {

  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    return function cleanup() {
      const pictures = getValues("kuvat")
      for (const picture in pictures) {
        if (picture instanceof File) {
          URL.revokeObjectURL(picture.kuva_url);
        }
      }
    }
  }, [])

  const removePicture = (fileIndex) => {

    const pictures = getValues("kuvat")

    const file = pictures[fileIndex]
    if (file instanceof File) {
      URL.revokeObjectURL(file.kuva_url);
    }

    setValue("kuvat", pictures.filter((item, index) => {
      return index !== fileIndex
    }))
  }

  const handleFileUpload = (e) => {

    if (!e.target.files) {
      return;
    }

    const pictures = getValues("kuvat")
    let files = []

    // Converting first the FileList to regular array before looping through it.
    // Checking that the amount of files will not be over 20
    // After that check that the file is a image and we don't have the same name in the array already.
    Array.from(e.target.files).forEach(file => {
      if (files.length + pictures.length === 20) {
        setErrorText("Lisää korkeintaan 20 kuvaa")
        setShowError(true);
        setTimeout(() => {
          setErrorText("")
          setShowError(false);
        }, 5000);
        return;
      }
      if (file.size > 15728640) { // This is 15MB which should be the limit for the size.
        setErrorText("Tiedostojen koko saa olla korkeintaan 15MB")
        setShowError(true);
        setTimeout(() => {
          setErrorText("")
          setShowError(false);
        }, 5000);
        return;
      }
      const fileName = file.name 

      if (file.type.split("/")[0] === "image" && !pictures.some(kuva => kuva.name === fileName)) {
        file.kuva_url = URL.createObjectURL(file)
        files.push(file)
      }
    });

    setValue("kuvat", pictures.concat(files))
  }

  return (
    <Container sx={containerStyle}>
      <input accept="image/*" id="picture-file-input" multiple type="file" name="kuvat" style={{ display: 'none' }} onChange={handleFileUpload}/>
      <label htmlFor="picture-file-input">
        <Button sx={buttonStyle} component="span" variant="contained" >
          Lisää kuvia
        </Button>
      </label>
      {showError &&
        <Alert severity="error" sx={alertStyle}>
          <AlertTitle>Kuvien lisääminen epäonnistui</AlertTitle>
          {errorText}
        </Alert>
      }
      <Controller
        name="kuvat"
        control={control}
        render={({ field: { value } }) => (
          <ImageList sx={imageListStyles} name="kuvalista">
            {value?.map((picture, i) => (
              <ImageListItem key={picture.kuva_url} >
                <img src={picture.kuva_url} alt="" style={{ height: 164, width: 164 }}/>
                <ImageListItemBar
                  sx={{ background: 'rgba(0,0,0,0)'}}
                  actionIcon={
                    <IconButton onClick={() => {removePicture(i)}} size="large">
                      <CloseIcon style={{ color: 'red' }}/>
                    </IconButton>
                  } 
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      />
    </Container>
  );
}