import React, { useState } from 'react';
import axios from "axios";
import { Button, Grid, Container, CircularProgress, Backdrop, Alert, AlertTitle, Tooltip} from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Puutavaralajit from './Puutavaralajit';
import Yleisetpuustotiedot from './Yleisetpuustotiedot';
import Kehitysluokkajakauma from './Kehitysluokkajakauma' 
import Kasvupaikkajakauma from './Kasvupaikkajakauma';

const buttonContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

const buttonStyle = {
  margin: (theme) => theme.spacing(2, 2),
  fontSize: '14px'
}

export default function Puustotiedot({control, setValue}) {

  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const [failureMessage, setFailureMessage] = useState('');

  const handleFileUpload = async (e) => {
    setLoading(true)

    if (!e.target.files) {
      return;
    }

    const fData = new FormData();
    fData.append("forestdatafile", e.target.files[0]);

    try {
      const response = await axios.post("/api/ilmoitus/kasittele-metsatiedot-xml", fData)
      document.getElementById('picture-file-input').value = ''

      updateFormValues(response.data)
      setLoading(false)
    } catch(error) {
      document.getElementById('metsavaratiedot-file-input').value = ''
      setFailureMessage(error.response.data.message ? error.response.data.message : 'Yritä myöhemmin uudelleen tai ole yhteydessä järjestelmänvalvojaan')
      setFailure(true)
      setLoading(false)

      setTimeout(() => {
        setFailure(false)
        setFailureMessage('')
      }, 5000);
    }
  }

  const updateFormValues = (formValues) => {

    if(Object.keys(formValues).length > 0){
      Object.entries(formValues).forEach(([name, value]) => setValue(name, value));
    }
  }

  return (
    <Container>
      <Grid container xs={12}>
        <Grid item xs={12} sx={buttonContainerStyle}>
          <input accept=".xml" id="metsavaratiedot-file-input" type="file" style={{ display: 'none' }} name='metsavaratiedot-input' onChange={(event) => handleFileUpload(event)}/>
          <label htmlFor="metsavaratiedot-file-input">
            <Button sx={buttonStyle} component="span" variant="contained" >
              Syötä tiedot XML-tiedostosta
              <Tooltip title="Voit syöttää puustotiedot suoraan XML-tiedostona. 
                Tämän XML-tiedoston tulee olla 1.8-version mukainen ja sen on oltava Foresta-metsätietojärjestelmästä peräisin. 
                Samalla pyydämme huomioimaan Nettimetsä.fi:n käyttöehdot." arrow placement="top-start"
              >
                <InfoOutlinedIcon sx={{ fontSize: 'large', marginLeft: '10px' }}/>
              </Tooltip>
            </Button>
          </label>
          {failure &&
            <Alert severity="error">
              <AlertTitle>Tiedoston käsittely epäonnistui</AlertTitle>
              {failureMessage}
            </Alert>
          }
        </Grid>
        <Grid item xs={12} md={6}>
          <Yleisetpuustotiedot control={control}/>
        </Grid>
        <Grid item xs={12} md={6}>
          <Puutavaralajit control={control}/>
        </Grid>
      </Grid>
      <Grid item container xs={12} >
        <Grid item xs={12} md={6}>
          <Kehitysluokkajakauma control={control}/>
        </Grid>
        <Grid item xs={12} md={6}>
          <Kasvupaikkajakauma control={control}/>
        </Grid>
      </Grid>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}