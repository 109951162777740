import React from 'react';
import { Controller } from "react-hook-form";
import { NumericFormat } from 'react-number-format';

import { Grid, List, ListSubheader, ListItem, TextField } from '@mui/material';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const headerStyle = {
  fontSize: '16px',
}

export default function Kasvupaikkajakauma({control}) {

  return (
      <div >
        <Grid sx={containerStyle} item container spacing={2} xs={12}>
          <Grid item xs={12}>
            <List subheader={<ListSubheader sx={headerStyle} >Kasvupaikkajakauma (ha)</ListSubheader>}>
              <ListItem>
                <Controller
                  name="lehto_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}} // Koon tulisi olla alle miljoona hehtaaria
                      label="Lehto"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
                <Controller
                  name="lehtomainenkangas_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="Lehtomainen kangas"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
              </ListItem>
              <ListItem>
                <Controller
                  name="tuorekangas_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="Tuore kangas"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
                <Controller
                  name="kuivahkokangas_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="Kuivahko kangas"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
              </ListItem>
              <ListItem>
                <Controller
                  name="kuivakangas_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="Kuiva kangas"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
                <Controller
                  name="karukkokangas_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="Karukkokangas"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
              </ListItem>
              <ListItem>
                <Controller
                  name="kitumaa_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="Kitumaa"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
                <Controller
                  name="joutomaa_koko"
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <NumericFormat
                      isNumericString={true}
                      decimalScale={2}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator=" "
                      decimalSeparator="."
                      isAllowed={(values) => {return values.value < 1000000}}
                      label="Joutomaa"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={value}
                      name={name}
                      onValueChange={values => {
                        onChange({
                          target: {
                            value: values.value
                          }
                        });
                      }}
                      customInput={TextField}
                    />
                  )}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
  );
}