import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import 'dayjs/locale/fi';

const customTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#286d2e',
      extralight: '#C9E0BE'
    },
  },
  typography: {
    fontFamily: [
      'Garet',
      'sans-serif',
    ].join(','),
  },
  components: {
    // Example: Customize the Button component
    MuiButton: {
      styleOverrides: {
        root: {
          border: '0px',
          borderRadius: 40,
        },
      },
    },
  },
});


const datePickerStyle = {
  width: (theme) => theme.spacing(28),
};

export default function BasicDateTimePicker({onChange, value, name, pickDisabled}) {
  return (
    <ThemeProvider theme={customTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi" localeText={{dateTimePickerToolbarTitle: 'Valitse päivä ja aika', cancelButtonLabel: 'Peruuta', clearButtonLabel: 'Tyhjennä' }}>
          <DateTimePicker sx={datePickerStyle} name={name} value={value} onChange={onChange} disabled={pickDisabled} />
      </LocalizationProvider>
    </ThemeProvider>
  );
}