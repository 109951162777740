import React, {useEffect, useState} from 'react';
import axios from "axios";

import {Container, Grid, List, ListItem, Typography, Button, Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, AlertTitle } from '@mui/material';

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    marginTop: (theme) => theme.spacing(2),
    marginBottom: (theme) => theme.spacing(8)
}

const buttonContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}

const buttonStyle = {
    width: '100%'
}

export default function UsersList() {

  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true);
  const [removeFailure, setRemoveFailure] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [removingUserEmail, setRemovingUserEmail] = useState("");

  const handleInitialRemoveTry = (email) => {
    setRemovingUserEmail(email)
    setOpenConfirmDialog(true)
  }

  const handleRemove = async () => {
    setLoading(true)
    setOpenConfirmDialog(false)
    try {
      await axios.delete("/api/admin/poista-kayttaja/" + removingUserEmail);
      getUsers();
    } catch(error) {
        setRemoveFailure(true)
        setLoading(false)
        setTimeout(() => {
            setRemoveFailure(false)
        }, 5000);
    }
  }

  useEffect(() => {
    getUsers();
  }, [])

  const getUsers = async () => {
    try {
      const response = await axios.get("/api/admin/kayttajat");
      setUsers(response.data);
      setLoading(false)
    } catch(error) {
      console.log(error);
      setLoading(false)
    }
  }

  const closeConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <Container sx={containerStyle}>
        {removeFailure &&
            <Alert severity="error">
                <AlertTitle>Käyttäjän poistaminen epäonnistui</AlertTitle>
            </Alert>
        }
      <List name="userlist">
        {users?.map((user) => {
          return (
            <ListItem key={user.email} >
                <Grid container item spacing={1} xs={12}>
                    <Grid item container xs={9}  >
                        <Grid item xs={12} sm={6} md={3} >
                        <Typography variant="body1">Sähköposti: {user.email}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1">Etunimi: {user.firstName}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1">Sukunimi: {user.lastName}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="body1">Status: {user.status} </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} sx={buttonContainerStyle}>
                        <Button sx={buttonStyle} variant="contained" color="secondary" onClick={() => {handleInitialRemoveTry(user.email)}} >
                        Poista
                        </Button>
                    </Grid>
                </Grid>
            </ListItem>
          )
        })}
      </List>
      <Dialog open={openConfirmDialog} onClose={closeConfirmDialog} >
        <DialogTitle>
          Haluatko varmasti poistaa käyttäjän?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Käyttäjän kaikki ilmoitukset poistuvat samalla
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleRemove}>Poista käyttäjä</Button>
          <Button variant="contained" onClick={closeConfirmDialog} autoFocus>Peruuta</Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}