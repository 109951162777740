import React from 'react';
import { useNavigate } from "react-router-dom";

import {Grid, List, ListItemButton, Typography, Avatar} from '@mui/material';

import { formatNumberWithSeparator } from '../../utils';

const listStyle = {
  width: '100%',
}

const listItemStyle = {
  borderRadius: '25px',
  boxShadow: 5,
  backgroundColor: 'white',
  marginBottom: (theme) => theme.spacing(2),
}

const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: "center"
}

const columnStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: {xs: 140, sm: 160, md: 220, lg: 240}
}

const imageStyle = {
  objectFit: 'contain',
  width: {xs: 160, sm: 180, md: 260, lg: 300},
  height: {xs: 140, sm: 160, md: 220, lg: 240},
  borderRadius: '25px'
}

const listItemBottomStyle = {
  display: 'flex',
  flexDirection: {xs: 'column', sm: 'row'},
  justifyContent: {xs: 'center', sm: 'space-between'},
}

const textStyle = {
  color:'black',
  textAlign: {xs:'center', sm:'left'},
  fontSize: {xs: '12px', sm: '16px'}
}

const smallerTextStyle = {
  color:'black',
  textAlign: {xs:'center', sm:'left'},
  fontSize: {xs: '12px', sm: '14px'},
}

const companyTextStyle = {
  color:'black',
  textAlign: {xs:'center', sm:'left'},
  fontSize: {xs: '12px', sm: '14px'},
  fontWeight: {xs: 'bold', sm: 'normal'}
}

const smallerTextCenterStyle = {
  color:'black',
  fontSize: {xs: '12px', sm: '14px'},
  textAlign: 'center'
}

const mobileVisibleStyle = {
  display: {xs: 'block', sm: 'none'}
}

const mobileHiddenStyle = {
  display: {xs: 'none', sm: 'block'}
}

export default function ItemsList({ilmoitukset}) {
  const navigate = useNavigate();

  const openIlmoitus = (ilmoitus_id) => {
    navigate('/haku/' + ilmoitus_id);
  }

  return (
    <List sx={listStyle} name="ilmoituslista">
      {ilmoitukset?.map((ilmoitus) => {
        return (
          <ListItemButton sx={listItemStyle} key={ilmoitus.ilmoitus_id} onClick={() => {openIlmoitus(ilmoitus.ilmoitus_id)}} elevation={3}>
            <Grid container spacing={2} sx={rowStyle}>
              <Grid item container spacing={1} xs={6} sm={4.5} >
                <Grid item xs={12} sx={mobileVisibleStyle}>
                  <Typography sx={smallerTextCenterStyle} variant="body2">
                    <b>{ilmoitus.tyyppi}</b> {ilmoitus.tyyppi !== 'Yhteismetsäosuus' ? ilmoitus.kiinteistonimi : ilmoitus.yhteismetsa_nimi}
                  </Typography>
                </Grid>
                <Grid item xs={12} >
                  <Avatar
                    alt="myynti-ilmoituksen kuva"
                    src={ilmoitus.kuva_url ? ilmoitus.kuva_url : "../image_placeholder.jpeg"}
                    sx={imageStyle}
                    loading="lazy"
                  />
                </Grid>
                <Grid item xs={12} sx={mobileVisibleStyle}>
                  <Typography sx={smallerTextCenterStyle} variant="body2">{ilmoitus.tarjouskauppa === 'true' ? `Tarjous jätettävä viimeistään ${ilmoitus.tarjouskauppa_paattyy}` : ""}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={6} sm={7.5} sx={columnStyle}>
                <Grid item xs={12} sx={mobileHiddenStyle}>
                  <Typography sx={smallerTextStyle} variant="body2">
                    <b>{ilmoitus.tyyppi}</b> {ilmoitus.tyyppi !== 'Yhteismetsäosuus' ? ilmoitus.kiinteistonimi : ilmoitus.yhteismetsa_nimi}
                  </Typography>
                </Grid>
                {ilmoitus.tyyppi !== 'Yhteismetsäosuus' ?
                  <Grid item container xs={12} >
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Typography sx={textStyle} variant="body1"><b>Hinta</b></Typography>
                        <Typography sx={textStyle} variant="body1">{formatNumberWithSeparator(ilmoitus.hintapyynto)} €</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} >
                      <Typography sx={textStyle} variant="body1"><b>Pinta-ala</b></Typography>
                      <Typography sx={textStyle} variant="body1">{Number(ilmoitus.koko).toFixed(1)} ha</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} sx={{display: ilmoitus.puu_maara ? 'block' : 'none' }}>
                      <Typography sx={textStyle} variant="body1"><b>Kuutiot</b></Typography>
                      <Typography sx={textStyle} variant="body1">{ilmoitus.puu_maara + " m³"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} >
                      <Typography sx={textStyle} variant="body1"><b>Sijainti</b></Typography>
                      <Typography sx={textStyle} variant="body1">{ilmoitus.maakunta}, {ilmoitus.kunta}</Typography>
                    </Grid>
                  </Grid>
                :
                  <Grid item container xs={12} >
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <Typography sx={textStyle} variant="body1"><b>Hinta</b></Typography>
                      <Typography sx={textStyle} variant="body1">{formatNumberWithSeparator(ilmoitus.hintapyynto)} €</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={9} >
                      <Typography sx={textStyle} variant="body1"><b>Myynnissä olevat osuudet</b></Typography>
                      <Typography sx={textStyle} variant="body1">{ilmoitus.yhteismetsa_osuudet}/{ilmoitus.yhteismetsa_kokonaisosuudet}</Typography>
                    </Grid>
                  </Grid>
                }
                <Grid item xs={12} sx={listItemBottomStyle}>
                  <Typography sx={smallerTextStyle} display={{xs: 'none', sm: 'block'}} variant="body2">{ilmoitus.tarjouskauppa === 'true' ? `Tarjous jätettävä viimeistään ${ilmoitus.tarjouskauppa_paattyy}` : ""}</Typography>
                  <Typography sx={companyTextStyle} variant="body2">{ilmoitus.yritysnimi ? ilmoitus.yritysnimi : "Yksityinen myyjä"}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </ListItemButton>
        )
      })}
    </List>
  );
}