import React from 'react';

import { Alert, AlertTitle } from '@mui/material';
import { Container } from '@mui/material';

const containerStyle = {
  marginTop: (theme) => theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

export default function RegistrationSuccess() {

  return (
    <Container maxWidth="xs" sx={containerStyle}>
      <Alert severity="success">
        <AlertTitle>Rekisteröityminen onnistui</AlertTitle>
          Sähköposti on vahvistettu.
      </Alert>
    </Container>
  );
}