import React from 'react';
import { Modal, Box, IconButton } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const arrowIconStyle = {
    fontSize: 54,
    color: 'white',
}

const ImageModal = ({ open, imageUrl, onClose, setNextPicture, setEarlierPicture, hasEarlierPictures, hasNextPictures }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '80vh',
            maxHeight: '80vh',
            boxShadow: 50,
            p: 5,
        }}
      >
        <IconButton
          sx={{
            display: hasEarlierPictures ? "block" : "none",
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
          }}
          onClick={setEarlierPicture}
        >
          <ArrowBackIcon sx={arrowIconStyle} />
        </IconButton>
        <IconButton
          sx={{
            display: hasNextPictures ? "block" : "none",
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
          }}
          onClick={setNextPicture}
        >
          <ArrowForwardIcon sx={arrowIconStyle} />
        </IconButton>
        <img src={imageUrl} alt="Modal Image" style={{ width: '90%', height: '90%', objectFit: 'contain' }} />
      </Box>
    </Modal>
  );
};

export default ImageModal;