import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

import {Button, TextField, Grid, Typography, Container, Link, CircularProgress } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

const containerStyle = {
  marginTop: (theme) => theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const circularProgressStyle = {
  margin: (theme) => theme.spacing(4)
}

const errorStyle = {
  marginTop: (theme) => theme.spacing(2),
  padding: "8px 16px",
}

export default function SendReset() {
  const [loading, setLoading] = useState(false);
  const [sentSuccess, setSentSuccess] = useState(false);
  const [sentFailure, setSentFailure] = useState(false);
  const [sentFailureMessage, setSentFailureMessage] = useState('');
  const { handleSubmit, control } = useForm({ defaultValues: { email: ""}});

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await axios.post("/api/user/salasana-resetointi", data)
      setSentSuccess(true)
      setLoading(false)
    } catch(error) {
      setSentFailure(true)
      if(error.response.data.message) {
        console.log(error.response.data.message)
        setSentFailureMessage(error.response.data.message);
      } else {
        setSentFailureMessage('Yritä myöhemmin uudelleen tai ole yhteydessä järjestelmänvalvojaan.')
      }
      setLoading(false)
      setTimeout(() => {
        setSentFailure(false)
        setSentFailureMessage('')
      }, 5000);
    }
  }

  return (
    <Container sx={containerStyle} maxWidth="xs">
      {loading?
          <CircularProgress color="primary" sx={circularProgressStyle}/>
      :
      <div>
        {!sentSuccess &&
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Typography component="body1" align="center" color="textSecondary">Syötä sähköpostisi, niin lähetämme sinulle linkin salasanan vaihtamiseksi.</Typography>
              </Grid>
              <Grid item xs={12} >
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: 'Syötä sähköposti',
                    maxLength: { value: 50, message: "Sähköpostin oltava korkeintaan 50 merkkiä" },
                    pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, message: "Tarkista sähköposti" }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField fullWidth label="Sähköposti" name="email" variant="outlined" value={value} onChange={onChange} autoFocus error={!!error} helperText={error ? error.message : null} />
                  )}
                />
              </Grid>
              <Grid item xs={12}> 
                <Button type="submit" fullWidth variant="contained" color="primary">
                  VAIHDA SALASANA
                </Button>
              </Grid>
              <Grid item xs>
                <Link href="/kirjautuminen" variant="body2">
                  Kirjaudu sisään
                </Link>
              </Grid>
              <Grid item>
                <Link href="/rekisteroityminen" variant="body2">
                  Oletko uusi käyttäjä?
                </Link>
              </Grid>
            <Grid/>
            </Grid>
            {sentFailure &&
              <Alert severity="error" sx={errorStyle}>
                <AlertTitle>Linkin lähettäminen epäonnistui</AlertTitle>
                {sentFailureMessage}
              </Alert>
            }
          </form>
        }
        {sentSuccess &&
          <Alert  severity="success">
            <AlertTitle>Linkin lähettäminen onnistui </AlertTitle>
            Sähköpostiisi on lähetetty linkki salasanan vaihtamiseksi.
          </Alert>
        }
      </div>
      }
    </Container>
  );
}